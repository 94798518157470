.scrolloff { // Added via javascript
  pointer-events: none;
}

.copyright {
  color: $brand-dark-black;
  font-family: $font-family-primary;
  font-size: $font-size-base;
  line-height: 24px;
  margin-top: 16px;
  word-wrap: break-word;
}
