@mixin make-nav-variant(
    $link-color,
    $link-color-hover,
    $link-bg,
    $link-bg-hover,
    $link-color-active,
    $link-bg-active,
    $link-color-disabled,
    $link-bg-disabled
  ) {
  > .nav-item > .nav-link,
  > .nav-item > .dropdown-toggle, /* Override Bootstrap Dropdown */
  .open > a /* Override Bootstrap Dropdown */ {
    @if ('' != $link-bg and transparent != $link-bg and none != $link-bg) {
      background-color: $link-bg;
    }

    color: $link-color;

    &:hover,
    &:focus {
      color: $link-color-hover;
      background-color: $link-bg-hover;
    }
  }

  > .nav-item > .nav-link.offie--active {
    color: $link-color-active;
    background-color: $link-bg-active;
  }

  .active > .nav-link {
    &,
    &:hover,
    &:focus {
      color: $link-color-active;
      background-color: $link-bg-active;
    }
  }

  .disabled > .nav-link {
    &,
    &:hover,
    &:focus {
      color: $link-color-disabled;
      background-color: $link-bg-disabled;
    }
  }

  // Fix Bootstrap 3.*
  li > a:hover,
  li > a:focus {
    color: $link-color-hover;
    background-color: $link-bg-hover;
  }

  .dropdown-menu > li > a {
    color: $link-color;
  }

  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    color: $link-color-hover;
    background-color: $link-bg-hover;
  }
}

@mixin make-navbar-variant(
    $navbar-bg,
    $navbar-brand-bg,
    $navbar-border-color,
    $navbar-shadow,
    $navbar-knob-bg,
    $navbar-knob-color
  ) {
  background-color: $navbar-bg;
  box-shadow: $navbar-shadow;

  .navbar-header,
  .navbar-brand,
  .navbar-knob {
    background-color: $navbar-brand-bg;
  }

  .nav-link {
    border-color: $navbar-border-color;
  }

  .navbar-knob-icon {
    fill: $navbar-knob-color;
  }
}
