// Card / *
.card-block {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 0 0;
}

.card-element--tags {
  margin: 0;

  &:has(.dash) {
    display: flex;
  }
}

.card-element--info {
  align-items: center;
  display: flex;
  margin-bottom: 8px;

  .dash {
    margin: 0 8px;
  }
}

.card-title {
  @include make-font-5();
  @include text-line-clamp(3);
  margin: 0;
}

.card-subtitle {
  @include make-font-8();
  line-height: 20px;
  margin: 0;
  text-transform: uppercase
}

.card-text {
  @include make-font-1();
  display: none;
  font-family: $font-family-primary;
  font-size: $font-size-base;
}

// Card / Spokesperson
.card--spokesperson {

  .card-subtitle {
    color: $text-color;
    font-family: $font-trenda-bold;
    font-size: $font-size-base;
    line-height: 20px;
    margin: 8px 0 0;
    text-transform: unset;
  }

  .card-text {
    display: block;
  }

  .card-btn {
    cursor: pointer;
  }

  &.card--alt {
    .card-img {
      margin-bottom: 0;
    }

    .card-block {
      background-color: $brand-light;
      border-radius: 4px;
      padding: $card-spacer-y $card-spacer-x;
    }
  }
}

// Card / Event
.card.card--event {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  padding: 0;

  .card-date-left {
    background-color: $brand-bright-gray;
    display: flex;
    flex-basis: 280px;
    flex-direction: column;
    justify-content: center;
    min-height: 176px;
    padding: 0;
    text-align: center;

    .card-element-date > span {
      color: $brand-primary;
      font-family: $font-trenda-bold;
      font-size: $font-size-page-title;
      letter-spacing: 0;
      line-height: 56px;
      text-align: center;
    }

    .card-element-month > span,
    .card-element-time > span {
      color: $brand-primary;
      font-family: $font-family-light;
      font-size: $font-size-xl;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
    }

    .card-element-month {
      margin-top: 8px;
    }

    .card-element-time {
      margin-top: 8px;

      > span {
        font-size: $font-size-base;
        line-height: 20px;
      }
    }
  }

  .card-block {
    display: flex;
    padding: 24px 0 24px 16px;
    position: relative;

    .card-element--info {
      margin: 0 0 16px 0;
    }

    .card-title {
      margin-bottom: 0;
      -webkit-line-clamp: 2;
    }

    .location {
      color: $brand-primary;
      font-family: $font-family-light;
      font-size: $font-size-base;
      line-height: 20px;
      margin-top: 8px;
    }
  }

  .card-element--info {
    margin-left: 54px;

    .card-btn {
      margin: 0;
      min-width: unset;
    }
  }

  &.card--comming {
    .card-date-left {
      background-color: $brand-primary;

      .card-element-date > span {
        color: $brand-white;
      }

      .card-element-month > span,
      .card-element-time > span {
        color: $brand-white;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    background-color: $brand-bright-gray;
    flex-direction: column;
    padding: 0 24px 30px 24px;

    .card-date-left {
      flex-basis: auto;
      margin-bottom: 0;
      min-height: auto;
      padding: 30px 0 24px;

      .card-element-date > span {
        color: $brand-primary;
        font-size: $font-size-xxl;
        line-height: 28px;
      }

      .card-element-month > span {
        color: $brand-primary;
        font-size: $font-size-lg;
        line-height: 24px;
      }
    }

    .card-block {
      flex-direction: column;
      padding: 0;

      .card-element--info {
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 0 8px 0;
      }
    }

    .card-element--info {
      margin: 24px 0 0 0;
    }

    &.card--comming {
      background-color: $brand-primary;

      .list--tags .label--tags {
        color: $brand-pink;
      }

      .dash,
      .card-subtitle,
      .card-title,
      .card-btn .card-btn-text,
      .location {
        color: $brand-white;
      }

      .card-btn {
        .card-btn-icon .wizicon path {
          fill: $brand-white;
        }
      }
    }
  }
}

// Card / Performer
.card--performer {
  .card-title {
    font-family: $font-family-secondary;
    font-size: $font-size-xl;
  }

  .card-subtitle {
    color: $text-muted;
  }

  .card-img {
    margin-bottom: $card-spacer-y;

    > img {
      border: 1px solid $brand-secondary;
      border-radius: 50%;
      margin: auto;
    }
  }

  .card-subtitle {
    min-height: $line-height-computed * 2;
    padding-bottom: 20px;
    position: relative;

    &:after {
      border-bottom: 2px solid $brand-primary;
      bottom: 0;
      content: '';
      display: block;
      left: 40%;
      position: absolute;
      width: 20%;
    }
  }

  &.card--grid {
    .card-img {
      width: 140px;
    }

    .card-title,
    .card-subtitle {
      text-align: center;
    }
  }

  &.card--list {
    .card-block {
      padding-left: 0;
      padding-right: 0;

      @media screen and (min-width: $screen-sm-min) {
        padding: 0;
      }
    }
  }
}

.card--topic {
  &.card--highlight {
    display: flex;
    flex-direction: row;
    min-height: 276px;

    .mask-card-img {
      max-width: 492px;
      width: 100%;
    }

    .card-block {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0 0 24px 40px;
    }

    .card-title {
      font-family: $font-trenda-bold;
      font-size: $font-size-xxxl-mb;
      line-height: 48px;
      -webkit-line-clamp: 6;
    }

    @media screen and (max-width: $screen-md) {
      min-height: auto;
      flex-direction: column;

      .mask-card-img {
        max-width: 100%;
      }

      .card-block {
        padding: 16px 0 0;

        .list--tags {
          .label--tags {
            color: $brand-pink;
          }
        }

        .dash,
        .card-subtitle,
        .card-title,
        .card-title a,
        .card-btn .card-btn-text {
          color: $brand-white;
        }

        .card-btn {
          .card-btn-icon .wizicon path {
            fill: $brand-white;
          }
        }
      }
    }
  }

  .card-btn {
    text-decoration: underline;
    text-underline-offset: 6px;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Card Expert
.card--expert {
  .card-img {
    padding-top: 100%;
  }

  .card-block {
    .card-title {
      flex: none;
      margin: 0;
    }

    .card-subtitle {
      color: $text-color;
      font-family: $font-trenda-semibold;
      font-size: $font-size-base;
      line-height: 20px;
      margin: 8px 0 0;
      text-transform: unset;
    }
  }

  .card-element.card-element--tags,
  .card-text,
  .card-element.card-element--webidentites {
    display: none;
  }
}

.card.card--asset {
  background-color: transparent;

  .card-block {
    .card-subtitle,
    .card-element.card-element--tags,
    .card-btn {
      display: none;
    }
  }

  .card-img {
    border-radius: 0;
    position: relative;

    &:hover {
      .card-overlay {
        opacity: 1;
        z-index: 3;
      }
    }

    .card-overlay {
      -webkit-transition: all ease .3s;
      align-items: center;
      background: $card-background-overlay;
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .share--default-alt {
        padding: 0;
        position: absolute;
        right: 8px;
        top: 8px;

        .list--share {
          display: flex;
          margin: 0;

          .list-item {
            align-items: center;
            border-radius: 50%;
            border: 1px solid $brand-white;
            display: flex;
            height: 20px;
            justify-content: center;
            margin: 0 2px;
            width: 20px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }

            .share-item {
              display: flex;
              line-height: 0;

              .wizicon {
                color: $brand-white;
                fill: $brand-white;
                height: 11px;
                width: 11px;
              }
            }
          }
        }
      }

      .card-btn--list {
        display: flex;
        flex-direction: column-reverse;
        max-width: calc(100% - 20px);
        position: absolute;

        .card-btn--download {
          margin-bottom: 16px;
        }

        .card-btn--readmore,
        .card-btn--download {
          align-items: center;
          display: flex;
        }

        .card-btn-icon {
          background-color: $brand-white;
          border-radius: 50%;
          display: inline-block;
          height: 32px;
          margin-right: 8px;
          width: 32px;

          svg.wizicon {
            fill: transparent;
            height: 32px;
            padding: 8px;
            width: 32px;

            path {
              stroke: $brand-primary;
            }

            &-icon-eye {
              path {
                stroke: unset;
              }
            }
          }
        }

        .card-btn-text {
          color: $brand-white;
          font-family: $font-trenda-light;
          font-size: $font-size-base;
          line-height: 32px;
        }
      }
    }
  }

  .card-title {
    margin-bottom: 0;
  }

  .card-img-icon {
    align-items: center;
    background-color: $card-background-icon;
    border-radius: 50%;
    top: 8px;
    display: flex;
    height: 36px;
    justify-content: center;
    left: 8px;
    position: absolute;
    width: 36px;
    z-index: 3;

    img,
    .wizicon {
      @include size(20px, 20px);

      &.wizicon-asset-video {
        @include size(18px, 18px);
      }

      path,
      .cls-1 {
        fill: $brand-white;
      }
    }
  }

  &.card--folder.card--alt,
  &.card--photo.card--alt,
  &.card--video.card--alt,
  &.card--file.card--alt {
    .card-img {
      border-radius: 4px;
    }

    .card-block {
      .card-title > a {
        -webkit-line-clamp: 6;
      }
    }
  }
}

.card--clipping {
  .card-title {
    -webkit-line-clamp: 2;
    margin-bottom: 8px;
  }

  .card-subtitle {
    padding-bottom: 8px;

    &-alt {
      color: $brand-byzantium;
      padding: 0;
    }
  }

  .card-btn {
    text-decoration: underline;
    text-underline-offset: 6px;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Cards
.card--feed-facebook {
  @include card-sizing(10px, 10px);
  @include make-card-layout--list(40px);

  background-color: $brand-light;
  padding: 15px;

  .card-img {
    margin-left: 0;
  }

  .card-title {
    font-family: $font-family-primary;
    font-size: $font-size-base;
    font-weight: 700;
  }

  .card-title-aside {
    color: $text-muted;
    font-size: $font-size-sm;
    font-weight: 400;
  }

  .card-subtitle {
    text-transform: inherit;
  }

  .card-text {
    color: $text-color;
    font-family: $font-family-primary;
  }

  @media screen and (max-width: $screen-sm) {
    .card-img {
      float: left;
      margin-right: 15px;
      width: 40px;

      > img {
        width: 40px;
      }
    }

    .card-block {
      overflow: hidden;
    }
  }
}

.card--contactform {
  .card-block {
    padding: 0;
  }
}
