.navbar {
  border-radius: 0;
  border-width: 0;
}

.navbar-content,
.navbar-element {
  @include clearfix();
}

.navbar-element--main {
  margin-right: 50px;
}

@media screen and (max-width: $grid-float-breakpoint-max) {
  .navbar-nav {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.navbar--app {
  background-color: $brand-light;
  border-width: 0;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.04);
}

.navbar-brand {
  border-right: 1px solid $border-color;
  display: block;
  float: none;
  padding: 0;
  position: relative;

  .navbar-brand-img {
    display: block; // Overrides common/style.css
    height: auto; // Overrides common/style.css
    margin: auto;
    max-width: 100%;
  }

  svg.navbar-brand-img {
    display: block;
    fill: #7E8083;
    height: 50px;
    margin: auto;
    padding: 10px 15px;
    width: 170px;
  }
}

.navbar--channel {
  @include navbar-size(46px, 10px, 0);

  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;

  @media screen and (max-width: $grid-float-breakpoint-max) {
    background-color: $brand-light;
    border-top-width: 0;
  }

  .nav--channel-1 {
    @include make-nav-variant(
      $text-color, // $link-color,
      $text-color, // $link-color-hover,
      transparent, // $link-bg,
      transparent, // $link-bg-hover,
      $brand-primary, // $link-color-active,
      transparent, // $link-bg-active,
      $text-muted, // $link-color-disabled,
      transparent, // $link-bg-disabled,
    );

    .nav-link {
      font-family: $font-family-primary;
      font-size: $font-size-sm;
      font-weight: 700;
      letter-spacing: .035em;
      height: 46px;
      padding-bottom: 13px; // fix for font-size-sm
      padding-top: 13px; // fix for font-size-sm
      text-transform: uppercase;

      .nav-link-text {
        vertical-align: middle;
      }

      .wizicon {
        @include size(13px, 13px);
        vertical-align: middle; // TODO: there's probably a better thing to do.
      }

      &:hover {
        color: $brand-primary;
      }
    }

    .offie--active.nav-link .wizicon,
    .active > .nav-link .wizicon {
      fill: $brand-primary;
    }

    @media screen and (min-width: $screen-sm) {
      > .nav-item {
        border-left: 1px solid $border-color;

        > .nav-link:after {
          @include draw-pseudo();

          border-bottom: 5px solid $brand-primary;
          border-bottom-color: inherit; // Have to be separated, does not work in short form "Zpx solid inherit"
          bottom: 0;
          left: 0;
          opacity: 0;
          position: absolute;
          right: 0;
          transition: opacity 0.15s;
        }

        &.active > .nav-link:after,
        > .nav-link.offie--active:after,
        > .nav-link:hover:after {
          opacity: 1;
        }

        > .offie-dropdown {
          left: -1px;
        }
      }

      > .nav-item:last-child {
        border-right: 1px solid $border-color;
      }
    }
  }

  .nav--channel-2 {
    .nav-link {
      padding-bottom: 10px;
      padding-top: 10px;
      text-transform: none;
    }
  }
}
