.list {
  @include list-unstyled();
}

.list--horizontal {
  @include list-component(10px);
}

.list--vertical {
  display: flex;
  margin: 0 -8px;

  .list-item {
    margin: 0;
    padding: 0 8px;
  }
}

.list--share {
  @extend .list--vertical;
}

.list--assets {
  .list-item:first-child {
    .list-item-element {
      border-top-width: 0;
    }
  }

  .list-item-link {
    @extend .link-default;

    font-weight: 600;
  }

  .list-header-element {
    border-bottom-width: 0;
    color: $text-muted;
    font-family: $font-family-primary;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .list-item:nth-child(even) .list-item-element {
    background-color: $brand-light-lter;
  }

  .list-item:nth-child(odd) .list-item-element {
    background-color: $brand-light-dk;
    border-right: 1px solid $brand-white;
  }

  .list-item .list-item-element .wizicon {
    fill: $brand-primary;
  }

  .list-item-element {
    border-top-width: 0;
    padding: 12px 15px;
    vertical-align: middle;
  }

  .list-item-element--type {
    .wizicon {
      @include size(30px, 30px);
      fill: $brand-dark-lter;
    }
  }

  .list-item-element--title {
    font-weight: 600;
  }

  .list-item-element--tags {
    .list--tags {
      margin-bottom: 0;
    }
  }

  .list-item-element--date {
    white-space: nowrap;
  }
}

.list--webidentities {
  @extend .list--vertical;
}

.list--content-download-1 {
  @include list-unstyled();

  margin-bottom: 0;

  .list-item {
    align-items: center;
    display: flex;
    justify-content: center;
    line-height: 20px;
    min-height: 34px;
    padding: 2px 8px;

    & + .list-item {
      border-top: 1px solid $border-color;
    }

    a {
      color: $text-color;
      font-family: $font-family-light;
      font-size: $font-size-base;
      font-weight: unset;
      line-height: 20px;
    }
  }
}

.list--content-download-2 {
  display: flex;
  margin: 0 -24px;

  .list-item {
    padding: 0 24px;
  }

  .section-btn {
    .section-btn-icon {
      .wizicon path {
        fill: transparent;
      }
    }

    &:active,
    &:active:focus,
    &:focus,
    &:hover {
      .section-btn-icon {
        .wizicon {
          fill: transparent;

          path {
            fill: transparent;
            stroke: $brand-primary;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    flex-direction: column;

    .list-item {
      .section-btn {
        width: 100%
      }

      & + .list-item {
        margin-top: 24px;
      }
    }
  }
}

.list--event_web_publication-anchors {
  @include list-unstyled();
  text-align: center;

  .list-item {
    margin-bottom: 20px;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .list-item {
      display: inline-block;

    }

    .list-item + .list-item {
      margin-left: 20px;
    }
  }
}

.list--event_web_publication--properties {
  display: flex;
  margin: 0 -20px;

  .list-item {
    padding: 0 20px;
  }

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    margin: 0;

    .list-item {
      padding: 0;

      & + .list-item {
        margin-top: 16px;
      }
    }
  }
}
