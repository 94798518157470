@mixin make-container($sm: false, $md: false, $lg: false) {
  margin-right: auto;
  margin-left: auto;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);

  @if $sm != false {
    @media (min-width: $screen-sm-min) {
      width: $container-sm;
    }
  }

  @if $md != false {
    @media (min-width: $screen-md-min) {
      width: $container-md;
    }
  }

  @if $lg != false {
    @media (min-width: $screen-lg-min) {
      width: $container-lg;
    }
  }
}

@mixin make-grid-layout($gutter, $child-selector) {
  @include clearfix();

  margin-left: -$gutter;
  margin-right: -$gutter;

  #{$child-selector} {
    float: left;
    padding-left: $gutter;
    padding-right: $gutter;
  }
}
