// Background
$bg-footer:#004489;
$bg-nav-text-color:#8FA9C9;

// Brand
$brand-primary: #052145;
$brand-secondary: #3D3D3D;

$brand-aureolin: #E8E100;
$brand-blue-sky: #52C1DD;
$brand-bright-gray: #E6E6E6;
$brand-byzantium: #752969;
$brand-cyan: #A4D8DE;
$brand-dark-black: #000000;
$brand-dark-blue: #012169;
$brand-dark: #373737;
$brand-light-blue: #3B618F;
$brand-light: #f6f7fd;
$brand-pink-second: #E7205F;
$brand-pink: #FA527D;
$brand-queen-blue-light: #3B618F;
$brand-cultured: #f8f8f8;
$brand-white: #ffffff;

$border-color: #e6eaed;

// Type
$font-family-bold: 'Vinci-Sans-Bold';
$font-family-light: 'Vinci-Sans-Light';
$font-family-medium: 'Vinci-Sans-Medium';
$font-family-regular: 'Vinci-Sans-Regular';
$font-family-primary: 'Trenda-Light', Arial, Helvetica, sans-serif;
$font-family-secondary: 'Trenda-Light', 'Arial Black', Arial, sans-serif;
$font-trenda-black: 'Trenda-Black';
$font-trenda-bold: 'Trenda-Bold';
$font-trenda-light: 'Trenda-Light';
$font-trenda-regular: 'Trenda-Regular';
$font-trenda-semibold: 'Trenda-Semibold';
$font-size-base: 16px;

$font-size-xxs: $font-size-base * 0.625; // ~ 10px
$font-size-xs: $font-size-base * 0.75; // ~ 12px
$font-size-sm: $font-size-base * 0.875; // ~ 14px
$font-size-md: $font-size-base * 1.15; // ~ 18px
$font-size-lg: $font-size-base * 1.25; // ~ 20px
$font-size-xlg: $font-size-base * 1.375; // ~ 22px
$font-size-xl: $font-size-base * 1.5; // ~ 24px
$font-size-xxl: $font-size-base * 2; // ~ 32px
$font-size-xxxl-mb: $font-size-base * 2.5; // ~ 40px
$font-size-xxxl: $font-size-base * 3.5; // ~ 56px
$font-size-page-title: $font-size-base * 4; // ~ 64px

$font-weight-extra-bold: unset;
$font-weight-bold: unset;
$font-weight-regular: unset;
$font-weight-light: unset;

$line-height-computed: $font-size-base * 1.42;

$text-color: $brand-dark-black;
$text-muted: #7E8083;
$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 10%);
$container-sm: 640px;
$container-md: 1076px;
$container-lg: 1076px;
$container-main: 1512px;

$grid-gutter-width: 40px;

// Buttons
$btn-border-radius-base: 72px;
$btn-font-family: $font-family-primary;
$btn-font-size: $font-size-md;
$btn-font-weight: unset;
$btn-primary-border: $brand-primary;
$btn-padding: 24px 32px;

// Cards
$card-background-overlay: #00000080;
$card-border-color: #eee;
$card-border-width: 0;
$card-box-shadow: none;
$card-btn-prototype: '.link';
$card-default-background-color: transparent;
$card-event-highlight-color: #3B618F;
$card-link-font-weight: 400;
$card-spacer-x: 8px;
$card-spacer-y: 8px;
$card-background-icon: $brand-primary;

// Features.
$feature-icon-width: 40px;
$feature-icon-border-color: transparent;

// Nav
$navbar-client-height:           117px;

// Sections
$section-bg: transparent;
$section-block-bg: none;
$section-block-position: relative;
$section-btn-prototype: '.btn-default';
$section-cap-x: 0px;
$section-cap-y: 20px;
$section-hero-text-color: #fff;
$section-margin: 0;
$section-position: relative;
$section-prototype: 'container';
$section-spacer-x: 0px;
$section-spacer-y: 30px;
$section-text-color-muted: $text-muted; // .section-text--secondary {}
$section-text-color: $text-color; // .section-text {} font color.
$section-title-border: 1px solid #eee;
$section-title-color-alt: #004489;
$section-title-font-family: $font-trenda-bold;
$section-title-font-size: $font-size-xxxl;
$section-title-font-weight: 700;
$section-title-line-height: 1.3;
$section-title-text-align: left;

// Webidentities
$webidentity-width: 40px;

// RS colors
$brand-dailymotion:     #13B1FC;
$brand-facebook:        #3b5998;
$brand-flickr:          #ff0084;
$brand-googleplus:      #dd4b39;
$brand-instagram:       #e1306c;
$brand-linkedin:        #0077b5;
$brand-pinterest:       #bd081c;
$brand-rss:             #f26522;
$brand-tiktok:          #000000;
$brand-twitter:         #000000;
$brand-viadeo:          #f07355;
$brand-youtube:         #ff0000;
$brand-sharing:         #ED4D4D;
$brand-soundcloud:      #ff8800;
$brand-email:           $brand-primary;

$webidentities: (
  'dailymotion': (
    'bg-color': $brand-dailymotion,
    'border-color': $brand-dailymotion,
    'color': #fff,
  ),
  'facebook': (
    'bg-color': $brand-facebook,
    'border-color': $brand-facebook,
    'color': #fff,
  ),
  'flickr': (
    'bg-color': $brand-flickr,
    'border-color': $brand-flickr,
    'color': #fff,
  ),
  'google-plus': (
    'bg-color': $brand-googleplus,
    'border-color': $brand-googleplus,
    'color': #fff,
  ),
  'instagram': (
    'bg-color': $brand-instagram,
    'border-color': $brand-instagram,
    'color': #fff,
  ),
  'linkedin': (
    'bg-color': $brand-linkedin,
    'border-color': $brand-linkedin,
    'color': #fff,
  ),
  'pinterest': (
    'bg-color': $brand-pinterest,
    'border-color': $brand-pinterest,
    'color': #fff,
  ),
  'rss': (
    'bg-color': $brand-rss,
    'border-color': $brand-rss,
    'color': #fff,
  ),
  'rss-two': (
    'bg-color': $brand-rss,
    'border-color': $brand-rss,
    'color': #fff,
  ),
  'soundcloud': (
    'bg-color': $brand-soundcloud,
    'border-color': $brand-soundcloud,
    'color': #fff,
  ),
  'tiktok': (
    'bg-color': $brand-tiktok,
    'border-color': $brand-tiktok,
    'color': #fff,
  ),
  'twitter': (
    'bg-color': $brand-twitter,
    'border-color': $brand-twitter,
    'color': #fff,
  ),
  'viadeo': (
    'bg-color': $brand-viadeo,
    'border-color': $brand-viadeo,
    'color': #fff,
  ),
  'youtube': (
    'bg-color': $brand-youtube,
    'border-color': $brand-youtube,
    'color': #fff,
  ),
);
