.btn {
  @include button-size($btn-font-size, 18px, $btn-font-size, 1, 0);

  letter-spacing: 0.095em;
  text-decoration: none;
  text-transform: none;

  &:active {
      -webkit-box-shadow: inset 0 3px 15px rgba(0, 0, 0, .05);
    box-shadow: inset 0 3px 15px rgba(0, 0, 0, .05);
  }

  .wizicon {
    @include size($btn-font-size, $btn-font-size);
    vertical-align: bottom;
  }
}

.btn-primary,
.btn-secondary {
  .wizicon {
    fill: #fff;
  }
}

.btn--secondary {
  @include button-huer(
    $brand-white, // $color,
    $brand-primary, // $color-hover,
    $brand-primary, // $bg,
    $brand-blue-sky, // $bg-hover,
    transparent, // $border-color,
    transparent, // $border-hover,
    $brand-primary // $color-active: $color,
  );

  align-items: center;
  border-radius: 100px;
  display: flex;
  height: 72px;
  justify-content: center;
  letter-spacing: normal;
  margin: 0 auto;
  min-width: 260px;
  padding: $btn-padding;
  position: relative;
  width: fit-content;

  .section-btn-icon {
    margin-left: 16px;
    vertical-align: middle;

    .wizicon {
      @include size(24px, 24px);
      fill: transparent;

      path {
        stroke: $brand-white;
      }
    }
  }

  .section-btn-text {
    font-family: $font-family-bold;
    font-size: $font-size-md;
    font-weight: unset;
    line-height: 20px;
    margin-top: -3px;
    text-align: center;
    vertical-align: middle;
  }

  &:hover {
    .section-btn-icon {
      .wizicon {
        path {
          stroke: $brand-primary;
        }
      }
    }
  }
}

#didomi-banner-btn {
  background-color: $brand-white;
  font-family: $font-family-primary;
  font-size: $font-size-sm;
  border-radius: 0;
  border-width: 1px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
}
