@mixin media-component($selector, $media-element-width, $media-element-spacer-x, $media-element-spacer-y, $text-line-height) {
  @include clearfix();
  display: block;

  #{$selector}-object,
  #{$selector}-icon {
    float: left;

    > img {
      display: block;
      width: 100%;
    }
  }

  #{$selector}-body {
    display: block;
    overflow: hidden;
    padding: $media-element-spacer-y $media-element-spacer-x;
  }

  #{$selector}-title {
    line-height: 1.3; // Resets redcurrents.css.
    margin: 0;
    margin-bottom: $media-element-spacer-y;
  }
}
