.section-element.section-stock-detail {
  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: $brand-light;
  }

  td {
    border-top: none;
    color: $brand-dark;
    font-family: $font-family-primary;
    font-size: $font-size-lg;
    padding: 25px 30px;

    &.title {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }
}
