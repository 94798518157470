.js-assets-foredrop {
  background-color: #fff;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s, visibility 0.2s;
  visibility: hidden;

  .js-buckle--loading & {
    opacity: 0.8;
    visibility: visible;
  }

  .layout--content & {
    left: 25%;
  }
}
