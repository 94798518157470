@mixin list-component($spacer-y) {
  @include list-unstyled();

  .list-item + .list-item {
    margin-top: $spacer-y;
  }
}


@mixin list-unstyled() {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
