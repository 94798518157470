.panel {
  background-color: transparent;
  border-width: 0;

  .panel-heading {
    padding: 0 0 0 24px;

    a {
      align-items: center;
      cursor: pointer;
      display: flex;
    }
  }

  .panel-title {
    font-family: $font-trenda-bold;
    font-size: $font-size-lg;
    font-weight: unset;
    line-height: 24px;
    overflow: hidden; // this makes the clearfix from floated.
    padding: 16px 0;
  }

  .panel-title-text {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .panel-title-icon {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
  }

  .panel-text {
    color: $text-muted;
    font-family: $font-family-primary;
    margin: 0;
    margin-bottom: 20px;
  }

  .panel-widget-item {
    display: inline-block;
    padding: 16px 24px;
    vertical-align: top; // Tricky thing to get rid of inline-block space.
  }
}

.collapse-body,
.panel-body {
  @include clearfix();
}

.panel-group .panel + .panel {
  margin-top: 16px; // Resets bootstrap.
}
