.nav-text {
  margin-bottom: 0;
  padding: $nav-link-padding;
}

.nav-link-text {
  display: inline-block;
  vertical-align: middle;
}

.nav-link-icon {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}
