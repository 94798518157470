$social-brand: (
  envelope:    $brand-email,
  facebook:    $brand-facebook,
  flickr:      $brand-flickr,
  google-plus: $brand-googleplus,
  instagram:   $brand-instagram,
  linkedin:    $brand-linkedin,
  pinterest:   $brand-pinterest,
  rss-two:     $brand-rss,
  rss:         $brand-rss,
  twitter:     $brand-twitter,
  viadeo:      $brand-viadeo,
  youtube:     $brand-youtube,
);

.share {
  padding: 10px;
  text-align: center; // For po.st to be centered.
  z-index: 1000; // To be above everything always.
}

.share.share--default {
  .share-title {
    display: none;
    font-size: $font-size-xxs;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .list--share {
      margin-left: auto;
      margin-right: auto;
      width: 32px;

      .list-item {
        margin-left: 0;

        .share-item--email {
          display: none;
        }
      }
    }
  }

  .share-item {
    display: block;

    .wizicon {
      @include size(32px, 32px);
      border-radius: 5px;
      padding: 6px;

      @each $social-network in envelope, facebook, flickr, google-plus, instagram, linkedin, pinterest, rss, rss-two, twitter, viadeo, youtube {
        &.wizicon-#{$social-network} {
          @include webidentity-huer(map-get($social-brand, #{$social-network}), $brand-white, $brand-white);
          fill: $brand-white;
        }
      }
    }
  }
}
