$nav-border-color: #e6e6e6;

.nav > li > a {
  font-weight: unset;
  text-decoration: none;
}

.nav--assets-options-1 {
  margin-right: -10px;

  @include nav-huer(
    $font-family-primary,
    600,
    $link-color,
    $link-hover-color,
    transparent,
    transparent,
    $link-hover-color,
    transparent,
    $text-muted,
    transparent,
    $border-color
  );

  .nav-item {
    float: left;
  }

  .nav-link {
    display: block;
    line-height: 20px;
    padding: 10px 10px;
  }

  .nav-link-text {
    font-size: $font-size-sm;
  }

  .nav-link-icon--secondary {
    margin-left: 3px;
  }

  .nav-item--format {
    padding-bottom: 15px;

    .nav-link {
      padding: 5px 10px 5px 10px;
    }

    .nav-link-icon {
      @include size(30px, 30px);

      display: block;
    }
  }
}

.nav--assets-options-2 {
  @include nav-huer(
    $font-family-primary,
    600,
    $link-color,
    $link-hover-color,
    transparent,
    transparent,
    $link-hover-color,
    transparent,
    $text-muted,
    transparent,
    $border-color
  );
}
