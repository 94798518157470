.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: '\00a0';
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-ar {
  background-image: url(~flag-icon-css/flags/4x3/ar.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/ar.svg);
}
.flag-icon-au {
  background-image: url(~flag-icon-css/flags/4x3/au.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/au.svg);
}
.flag-icon-be {
  background-image: url(~flag-icon-css/flags/4x3/be.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/be.svg);
}
.flag-icon-br {
  background-image: url(~flag-icon-css/flags/4x3/br.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/br.svg);
}
.flag-icon-ca {
  background-image: url(~flag-icon-css/flags/4x3/ca.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/ca.svg);
}
.flag-icon-ch {
  background-image: url(~flag-icon-css/flags/4x3/ch.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/ch.svg);
}
.flag-icon-de {
  background-image: url(~flag-icon-css/flags/4x3/de.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/de.svg);
}
.flag-icon-es {
  background-image: url(~flag-icon-css/flags/4x3/es.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/es.svg);
}
.flag-icon-fi {
  background-image: url(~flag-icon-css/flags/4x3/fi.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/fi.svg);
}
.flag-icon-fr {
  background-image: url(~flag-icon-css/flags/4x3/fr.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/fr.svg);
}
.flag-icon-gb {
  background-image: url(~flag-icon-css/flags/4x3/gb.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/gb.svg);
}
.flag-icon-hk {
  background-image: url(~flag-icon-css/flags/4x3/hk.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/hk.svg);
}
.flag-icon-id {
  background-image: url(~flag-icon-css/flags/4x3/id.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/id.svg);
}
.flag-icon-ie {
  background-image: url(~flag-icon-css/flags/4x3/ie.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/ie.svg);
}
.flag-icon-in {
  background-image: url(~flag-icon-css/flags/4x3/in.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/in.svg);
}
.flag-icon-is {
  background-image: url(~flag-icon-css/flags/4x3/is.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/is.svg);
}
.flag-icon-it {
  background-image: url(~flag-icon-css/flags/4x3/it.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/it.svg);
}
.flag-icon-jp {
  background-image: url(~flag-icon-css/flags/4x3/jp.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/jp.svg);
}
.flag-icon-kr {
  background-image: url(~flag-icon-css/flags/4x3/kr.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/kr.svg);
}
.flag-icon-lu {
  background-image: url(~flag-icon-css/flags/4x3/lu.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/lu.svg);
}
.flag-icon-mx {
  background-image: url(~flag-icon-css/flags/4x3/mx.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/mx.svg);
}
.flag-icon-nl {
  background-image: url(~flag-icon-css/flags/4x3/nl.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/nl.svg);
}
.flag-icon-no {
  background-image: url(~flag-icon-css/flags/4x3/no.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/no.svg);
}
.flag-icon-pl {
  background-image: url(~flag-icon-css/flags/4x3/pl.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/pl.svg);
}
.flag-icon-pt {
  background-image: url(~flag-icon-css/flags/4x3/pt.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/pt.svg);
}
.flag-icon-ro {
  background-image: url(~flag-icon-css/flags/4x3/ro.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/ro.svg);
}
.flag-icon-ru {
  background-image: url(~flag-icon-css/flags/4x3/ru.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/ru.svg);
}
.flag-icon-se {
  background-image: url(~flag-icon-css/flags/4x3/se.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/se.svg);
}
.flag-icon-sg {
  background-image: url(~flag-icon-css/flags/4x3/sg.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/sg.svg);
}
.flag-icon-tr {
  background-image: url(~flag-icon-css/flags/4x3/tr.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/tr.svg);
}
.flag-icon-us {
  background-image: url(~flag-icon-css/flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/us.svg);
}
.flag-icon-eu {
  background-image: url(~flag-icon-css/flags/4x3/eu.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/eu.svg);
}
.flag-icon-gb-eng {
  background-image: url(~flag-icon-css/flags/4x3/gb-eng.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/gb-eng.svg);
}
.flag-icon-un {
  background-image: url(~flag-icon-css/flags/4x3/un.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(~flag-icon-css/flags/1x1/un.svg);
}
