a,
.link,
.btn-link {
  color: $link-color;

  &:focus,
  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
}

a.disabled,
.link.disabled {
  color: $btn-link-disabled-color !important;
  cursor: $cursor-disabled !important;

  svg {
    fill: $btn-link-disabled-color !important;
  }
}
