// Variables
$color-primary: #30287e;
$color-secondary: #fff;
$color-danger: #c00;
$color-hover: #eee;
$font-color: #000118;
$border-color: #ddd;
$color-placeholder: #ddd;
$transition-time: .3s;

.wiztopic-select-container {
  background-color: $color-secondary;
  position: absolute;
  width: 100%;
  z-index: 1000;
  // overflow: auto;

  .wiztopic-select-header {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: 1000;

    .wiztopic-select-search {
      background-color: $color-secondary;
      border: 1px solid $border-color;
      box-sizing: border-box;
      color: $font-color;
      display: block;
      font-size: 16px;
      padding: 8px 59px 8px 15px;
      width: 100%;

      &::-webkit-input-placeholder {
        color: $color-placeholder;
        font-size: 14px;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: $color-placeholder;
        font-size: 14px;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $color-placeholder;
        font-size: 14px;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: $color-placeholder;
        font-size: 14px;
        opacity: 1;
      }
    }

    .wiztopic-select-trigger {
      background-color: $color-hover;
      border: 1px solid $border-color;
      cursor: pointer;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 44px;

      &::before {
        border: 2px solid $font-color;
        border-width: 2px 0 0 2px;
        bottom: 7px;
        content: '';
        height: 15px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate(225deg);
        transition: all $transition-time;
        width: 15px;
      }
    }
  }

  .wiztopic-select-body {
    background-color: $color-secondary;
    display: none;
    max-height: 250px;
    overflow: auto;

    .wiztopic-select-body-wrap {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;

      .wiztopic-select-item {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;
        padding: 15px;
        transition: all $transition-time;

        &.wiztopic-select-item-not-found {
          display: none;
        }

        .wiztopic-select-item-mark {
          background-color: $color-secondary;
          border: 1px solid $border-color;
          height: 20px;
          position: relative;
          transition: all $transition-time;
          width: 20px;

          &::before {
            color: $color-primary;
            content: '\2713';
            font-size: 14px;
            left: 3px;
            position: absolute;
            top: -1px;
            transform: scale(0);
            transition: all $transition-time;
          }
        }

        .wiztopic-select-item-label {
          padding-left: 12px;
          width: 100%;
        }

        &.wiztopic-select-item-selected {
          .wiztopic-select-item-label {
            font-weight: bolder;
          }

          .wiztopic-select-item-mark {
            border: 1px solid $color-primary;

            &::before {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }

  &.wiztopic-select-single-type {
    .wiztopic-select-body {
      .wiztopic-select-body-wrap {
        .wiztopic-select-item {
          .wiztopic-select-item-mark {
            border-radius: 50%;
          }
        }
      }
    }
  }

  &.wiztopic-select-multiple-type {
    .wiztopic-select-body {
      .wiztopic-select-body-wrap {
        .wiztopic-select-item {
          .wiztopic-select-item-mark {
            border-radius: 3px;
          }
        }
      }
    }
  }

  .wiztopic-select-footer {
    display: none;
  }

  &.wiztopic-select-is-open {
    border: 1px solid $border-color;
    padding: 43px 0 0;

    .wiztopic-select-header {
      .wiztopic-select-search {
        border-width: 0 0 1px;
        padding: 12px 59px 12px 15px;
      }

      .wiztopic-select-trigger {
        border: 0;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;

        &::before {
          bottom: -7px;
          transform: rotate(45deg);
        }
      }
    }

    .wiztopic-select-body {
      display: block;
      margin: 0;
    }

    .wiztopic-select-footer {
      display: block;
    }
  }
}

.form--subject {
  .form-group {
    position: relative;
    margin-bottom: 45px;

  }
}
