.layout-event {
  .layout-hook--main  {
    .section--jumbotron-event {
      padding-bottom: 52px;

      .section-element {
        align-items: flex-start;
        flex-direction: column;
      }

      .section-title {
        max-width: 748px;
        text-align: left;
        width: 100%;
      }

      .section-element--event-times {
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;

        .element-date {
          color: $brand-primary;
          font-family: $font-trenda-black;
          font-size: $font-size-page-title;
          line-height: 56px;
          text-transform: uppercase;
        }

        .element-month {
          color: $brand-primary;
          font-family: $font-family-light;
          font-size: $font-size-xl;
          line-height: 32px;
          margin-top: 8px;
        }
      }

      @media screen and (max-width: $screen-md) {
        padding-bottom: 16px;

        .section-element--event-times {
          margin-bottom: 16px;
          position: relative;
          right: unset;
          text-align: left;
          top: unset;

          .element-date {
            font-size: $font-size-xxl;
            line-height: 28px;
          }

          .element-month {
            font-size: $font-size-base;
            line-height: 24px;
          }
        }
      }
    }

    .section.section--event-text-content {
      padding-bottom: 0;
      padding-top: 0;

      .section-wrapper {
        margin: 0 auto;
      }

      .section-composite {
        margin-bottom: 0;
      }
    }

    .content-text {
      font-size: $font-size-base;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  .layout-hook--content {
    .section-wrapper {
      display: flex;

      .left-block {
        flex-basis: 358px;
        margin-right: 40px;

        &:not(:has(.section-img)) .section-text--state {
          display: inline-block;
          margin-bottom: 40px;
          position: relative;
        }

        .section-img {
          height: 358px;
          max-width: 358px;
          width: 100%;

          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }

        .section-text--state {
          background-color: $brand-primary;
          color: $brand-white;
          font-family: $font-trenda-bold;
          font-size: $font-size-base;
          line-height: 24px;
          padding: 8px 16px;
          position: absolute;
          top: 0;
        }

        .section-bottombar {
          margin-top: 40px;

          .section-btn {
            margin: 0;
          }
        }
      }

      .right-block {
        margin: 0;
        flex: 1;

        .section--event {
          margin-bottom: 40px;

          .content-text {
            font-size: $font-size-base;
          }
        }

        .section-header {
          .section-title {
            font-family: $font-trenda-bold;
            font-size: $font-size-xl;
            line-height: 24px;
            padding-bottom: 40px;
            text-align: left;
            text-transform: unset;
          }
        }

        .section--details-info {
          margin-bottom: 40px;

          .list--event_web_publication--properties:not(:has(.mediaobj--eventinfo)) {
            display: none;
          }

          .list--event_web_publication--properties {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;

            .mediaobj--eventinfo {
              padding: 0 20px;
              flex-basis: 50%;

              .mediaobj-object {
                .wizicon-icon-clock {
                  margin-top: -3px;
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      padding-top: 32px;

      .section-wrapper {
        flex-direction: column;

        .left-block {
          margin: 0 0 40px;
          flex-basis: auto;

          .section-img {
            @include image-view(1, 1);
            height: auto;
            margin: 0 -16px;
            max-width: calc(100% + 32px);
            padding-top: calc(100% + 32px);
            width: calc(100% + 32px);
          }

          .section-text--state {
            left: -16px;
          }

          .section-bottombar .section-btn {
            margin: 0 auto;
          }
        }

        .right-block {
          .section--details-info {
            .list--event_web_publication--properties {
              .mediaobj--eventinfo {
                flex-basis: auto;
                padding: 0;
                width: 100%;

                & + .mediaobj--eventinfo {
                  margin-top: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
