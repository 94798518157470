.datetimepicker table tr td span.active {
  &,
  &.active,
  &.disabled {
    &,
    &:hover {
      background-color: $brand-primary;
      background-image: none;
    }
  }
}
