.card {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.card-title {
  line-height: 1.2; // Resets something from redcurrents.css
  overflow-wrap: break-word;

  a {
    @extend .link-reset; // .link-default ?
  }
}

.card-img {
  @include image-view(16, 9);

  border-radius: 4px;
  overflow: hidden;

  &,
  img {
    max-width: 100%;
    width: 100%;
  }
}

.card-block > *:last-child {
  margin-bottom: 0 !important; // !important is required to ensure we don't have double space below for the last element.
}

.card-block .card-img {
  margin-bottom: $card-spacer-y;
}

.card-link {
  font-weight: $card-link-font-weight;
}

.card-btn {
  align-items: center;
  display: flex;
  margin-top: 32px;

  .card-btn-icon {
    margin-left: 16px;

    .wizicon {
      @include size(24px, 24px);

      fill: $brand-primary;

      path {
        fill: $brand-primary;
      }
    }
  }

  .card-btn-text {
    color: $brand-primary;
    font-family: $font-trenda-bold;
    font-size: $font-size-md;
    line-height: 20px;
    margin-top: -3px;
  }

  @media screen and (max-width: $screen-md) {
    margin-top: 24px;
  }
}

// Default sizing.
@include card-sizing($card-spacer-x, $card-spacer-y);
