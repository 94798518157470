.modal-content {
  .section.section--performer {
    margin-bottom: 20px;
    padding: 0;
  }

  .card-block {
    padding: 0;
  }

  .card-img {
    @include size(140px, 140px);
    float: left;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .card-subtitle:after {
    left: 30%;
  }

  .card-subtitle {
    text-transform: none;
    font-weight: 600;
  }

  .card-infos {
    @include make-font-7();
    color: $brand-secondary;
    margin-bottom: 5px;
    margin-top: 20px;
    text-transform: none;
  }

  .card-text {
    clear: left;
  }

  .section--expertises {
    .section-header {
      padding-bottom: 20px;
    }

    .section-title {
      text-align: left;
    }

    .panel--expert_expertise {
      .panel-body {
        padding: 20px;
      }

      &.panel--highlight {
        .wizicon {
          fill: $brand-primary;
        }
      }
    }
  }

  .modal-footer {
    .section-btn {
      @include make-button--primary-2();
    }
  }
}
