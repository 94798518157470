.section-main-content.section-stock_highlight {
  background-color: $brand-light;
  padding: 20px 10px;

  .investors-highlight-value,
  .investors-highlight-infos {
    display: inline-block;
  }

  .investors-highlight-value {
    vertical-align: top;
  }

  .investors-highlight-percent {
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
  }

  .card-investors-last-open {
    font-family: $font-family-secondary;
    font-weight:$font-weight-regular;
  }

  .card-action-investors-highlight-date {
    font-size: $font-size-xxs;
    font-weight: $font-weight-light;

    p {
      margin-bottom: 0;
    }
  }

  .section-btn {
    margin-bottom: 0;
  }
}
