.section--sidebar {
  .section--webidentities {
    .section-title {
      padding-bottom: 8px;

      .section-title-icon {
        display: none;
        float: right;
        transform: rotate(-180deg);
        transition: transform .3s ease-out;

        .wizicon {
          fill: $brand-dark;
        }
      }

      & > .collapsed {
        .section-title-icon {
          transform: rotate(0deg);
          transition: transform .3s ease-out;
        }
      }
    }
  }

  .section-header {
    padding-bottom: 0;
  }

  .section--features {
    .card--feature {
      @include make-card-layout--list(40px, false);
    }
  }

  .section--spokespersons {
    .section-btn {
      @include make-button--primary-1();
      padding: 10px 0;

      .section-btn-text {
        font-weight: $font-weight-regular;
      }
    }
  }

  .section--newsletter-alt {
    background-color: $brand-cyan;
    margin-bottom: 32px;
    padding: 60px 32px 24px;

    .section-title {
      padding-bottom: 16px;
    }

    .section-text {
      font-family: $font-family-medium;
      font-size: $font-size-base;
      letter-spacing: 0;
      line-height: 24px;
    }

    .section-footer {
      padding-top: 32px;
      text-align: left;

      .section-btn {
        min-width: unset;
      }
    }
  }

  .section--webidentities {
    &.section--highlight {
      background-color: $brand-cyan;
      margin-bottom: 32px;
      padding: 60px 32px 32px;

      .section-block {
        .group-sidebar-social {
          align-items: center;
          display: flex;
          margin-bottom: 0;
        }

        .follow-us-title {
          color: $brand-secondary;
          display: none;
          font-size: $font-size-lg;
          line-height: 24px;
          padding-right: 7px;
        }

        .list--webidentities {
          display: flex;
          line-height: 0;
          margin: 0;

          .list-item {
            margin: 0;

            .webidentity {
              background-color: $brand-queen-blue-light;
              border: 1px solid $brand-queen-blue-light;
              display: none;
              height: 32px;
              margin: 0 4px;
              width: 32px;

              .wizicon {
                & path {
                  fill: $brand-cyan;
                }
              }

              &.webidentity-facebook,
              &.webidentity-linkedin,
              &.webidentity-twitter,
              &.webidentity-youtube,
              &.webidentity-tiktok {
                display: block;
              }
            }
          }
        }
      }

      .section-footer {
        padding: 0;
      }
    }
  }
}

.section--tags {
  &.section--highlight-mobile {
    display: none;

    .section-footer {
      display: none;
    }
  }

  &.section--highlight {
    background-color: $brand-primary;
    margin-bottom: 0;
    padding: 80px 32px 64px;

    .section-header {
      .section-title {
        color: $brand-pink;
        border-bottom: none;
        padding-bottom: 56px;
        pointer-events: none;

        .section-title-icon {
          display: none;
        }
      }
    }

    .list--tags {
      margin: 0 -8px;
      justify-content: center;

      .list-item {
        margin-bottom: 16px;
        padding: 0 8px;

        .label--tags {
          background-color: transparent;
          border-radius: 100px;
          border: 2px solid $brand-white;
          color: $brand-white;
          font-family: $font-trenda-bold;
          line-height: 16px;
          padding: 16px 24px;
          text-transform: unset;
        }

        &:hover {
          .label--tags {
            background-color: $brand-blue-sky;
            border-color: $brand-blue-sky;
            color: $brand-primary;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  .section--tags {
    &.section--highlight {
      padding: 40px 0 24px;

      .section-header {
        .section-title {
          padding-bottom: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .section--sidebar {
    .section--webidentities {
      &.section--highlight {
        margin: 0 -16px 32px -16px;
        padding: 54px 16px 32px;
      }
    }

    .section--newsletter-alt {
      margin: 0 -16px;
      padding: 54px 16px 32px;

      .section-footer {
        text-align: center;
      }
    }

    .section--tags {
      &.section--highlight {
        margin: 0;
        padding: 54px 16px 32px;
      }
    }
  }
}
