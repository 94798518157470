.pagination {
  margin: 0;
}

.pagination > li > a,
.pagination > li > span {
  background: transparent;
  border: 0;
  color: $brand-primary;
  font-family: $font-family-light;
  font-size: $font-size-base;
  line-height: 16px;

  &:hover {
    background: transparent;
  }

  .wizicon {
    fill: $brand-primary;
  }
}

.pagination > .active > span,
.pagination > .active > span:hover {
  background: transparent;
  color: $brand-primary;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover {
  color: #777 !important;
  cursor: not-allowed;
}
