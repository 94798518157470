.svgfilters {
  display: none; // hide the .svgfilters element, filters are referenced via CSS.
}

.layout-hook {
  @media (min-width: 1200px) {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
  }

  &.layout-hook--header {
    overflow: visible;
  }
}

.layout-hook.layout-hook--content {
  .section-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: $screen-md) {
    .section-wrapper {
      max-width: $container-md;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: $screen-lg) {
    .section-wrapper {
      max-width: $container-lg;
    }
  }
}

.layout--show {
  .layout-hook--main {
    .section:not(.section--jumbotron):not(.section--jumbotron-content):not(.layout-hook--content):not(.section--contact-footer) {
      &.section--odd {
        @include background-alternate ($brand-white);
      }

      &.section--even {
        @include background-alternate ($brand-primary);
      }
    }
  }
}

.layout--show {
  .layout-hook--main {
    .section:not(.section--jumbotron):not(.section--jumbotron-content):not(.layout-hook--content) {
      padding: 80px 0 40px;

      @media screen and (max-width: $screen-md) {
        padding-top: 40px;
      }
    }
  }

  &.layout--web_publication-show {
    .layout-hook--content {
      padding: 16px 0 56px;

      .content-text {
        font-family: $font-family-light;

        b, h2, h3, h4, strong {
          font-family: $font-family-bold;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }

      .section-content-meta-info {
        &.show-on-mobile {
          display: none;
        }
      }

      .section--content {
        margin-bottom: 0;

        a:not(.btn) {
          color: $brand-byzantium;
          font-family: $font-trenda-bold;
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }

      .section--collapses {
        margin: 32px 0 0;
      }
    }

    .section--contact-footer.show-on-mobile {
      display: none;
    }

    .section--assets {
      padding-bottom: 80px;

      .section-container {
        max-width: 100%;
        width: 100%;
      }

      .section-block {
        margin: auto;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
      }

      .gallery-list {
        display: flex;
        flex-wrap: nowrap;
        margin-left: 16%;
        transition: transform 0.5s ease;

        .gallery-item {
          min-width: 82.15%;
          padding: 0 20px;
          width: auto;

          &.active {
            z-index: 1;
          }
        }
      }

      .group-indicator {
        display: flex;
        margin: 16px 0 0 200px;

        .btn-prev, .btn-next {
          background-color: $brand-white;
          border-radius: 50%;
          border: 2px solid $brand-primary;
          cursor: pointer;
          margin: 0 6px;
          padding: 18px;
          position: relative;
          z-index: 2;

          .wizicon {
            width: 24px;
            height: 24px;

            path {
              fill: $brand-primary;
            }
          }

          &:focus {
            border-color: $brand-primary;
          }
        }

        .btn-prev {
          .wizicon {
            -webkit-transform: rotate(180deg);
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      .layout-hook--main {
        display: flex;
        flex-direction: column;

        .section--jumbotron-web_publication {
          order: 1;
        }

        .layout-hook--content {
          order: 2;
        }

        .section.section--assets {
          order: 3;
        }

        .section--contact-footer.show-on-mobile {
          margin-top: 32px;
          order: 4;
        }

        .section--news {
          order: 5;
        }

        .section--events {
          order: 6;
        }

        .section--clippings {
          order: 7;
        }

        .section--experts {
          order: 8;
        }

        .section--assets {
          .section-title {
            display: none;
          }
        }
      }

      .layout-hook--content {
        padding-bottom: 0;

        .section-content-meta-info {
          &.show-on-mobile {
            display: block;

            .section-element--download {
              margin: 0;

              .btn--secondary {
                margin-top: 32px;
              }
            }
          }
        }
      }

      .section--contact-footer.show-on-mobile {
        display: block;
      }

      .section--assets {
        padding: 40px 0 0;

        .section-container {
          padding: 0;
        }

        .gallery-list {
          margin: 0;

          .gallery-item {
            min-width: 100%;
            padding: 0;
            width: 100%;
          }
        }

        .group-indicator {
          margin: 0 0 0 16px;

          .btn-prev, .btn-next {
            padding: 12px;
          }
        }
      }

      .layout-hook--footer {
        .section--contact-footer {
          display: none;
        }
      }
    }
  }

  &.layout--expert-show {
    .layout-hook--content {
      padding-bottom: 80px;

      .section--combine {
        display: flex;
        margin: 0;

        .left-block {
          flex-basis: 358px;
        }

        .right-block {
          flex: 1;
        }

        .left-block {
          margin: 0;

          .section-img {
            height: 358px;
            max-width: 358px;
            width: 100%;

            img {
              height: 100%;
              object-fit: cover;
              width: 100%;
            }
          }
        }

        .right-block {
          margin: 0;
          padding-left: 40px;

          .section--content {
            margin-bottom: 40px;

            .content-text {
              font-size: $font-size-base;
              line-height: 24px;
            }
          }

          .section--expertises {
            margin: 0;

            .section-header {
              .section-title {
                font-size: $font-size-xl;
                line-height: 24px;
                padding: 16px 0 24px;
                text-align: left;
                text-transform: unset;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      .layout-hook--content {
        padding: 32px 0 40px;

        .section--combine {
          flex-direction: column;

          .left-block {
            flex-basis: auto;

            .section-block {
              margin: 0 -16px;
            }

            .section-img {
              @include image-view(1, 1);
              max-width: 100%;
            }
          }

          .right-block {
            padding: 24px 0 0;
          }
        }
      }
    }
  }
}

.content-text {
  // Base for vertical rythm: everything will be a multiple of this.
  $font-size: $font-size-lg;
  $line-height: 24px;
  font-family: $font-family-primary;
  word-break: break-word;

  // Setup base style.
  font-size: $font-size;
  line-height: $line-height;

  a,
  a:active,
  a:focus,
  a:hover {
    color: $brand-primary;
  }

  b, strong {
    font-family: $font-trenda-bold;
  }

  blockquote {
    border-left: none;
    padding-left: 101px;
    position: relative;

    &::before {
      background-image: url('../../../images/svgicons/quotes.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: 105px;
      position: absolute;
      top: 0;
      width: 154px;
    }

    p {
      color: $brand-primary;
      font-family: $font-trenda-bold;
      font-size: $font-size-lg;
      font-style: italic;
      line-height: 24px;
      padding-left: 138px;
      padding-top: 8px;
      position: relative;

      a {
        color: $brand-primary;
        text-decoration: underline;

        &:hover,
        &:focus {
          color: $brand-primary;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    blockquote {
      padding: 105px 0 0;

      p,
      pre {
        padding-left: 0;
        padding-right: 0;
        white-space: normal;
        word-break: break-word;
      }
    }
  }

  pre {
    color: $brand-primary;
    font-family: $font-family-light;
    font-size: $font-size-lg;
    font-weight: $font-weight-light;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 138px;
    white-space: normal;
  }

  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5
  h6, .h6 {
    line-height: 1.25;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: $font-size-xxl;
    margin-top: 2 * $line-height;
    margin-bottom: 0.75 * $line-height;
  }

  h3 {
    font-size: $font-size-xxl;
    margin-top: 1.5 * $line-height;
    margin-bottom: 0.5 * $line-height;
  }

  h4 {
    font-size: $font-size-xl;
    margin-top: 1.25 * $line-height;
    margin-bottom: 0.5 * $line-height;
  }

  // Handle special-case when two titles are following each other.
  h2 + h2 {
    margin-top: 0.75 * $line-height;
  }

  h2 + h3,
  h3 + h3,
  h3 + h4,
  h4 + h4 {
    margin-top: 0.5 * $line-height;
  }

  p {
    margin-top: 0;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: $screen-md) {
      margin-bottom: 16px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  table td,
  table th {
    padding: 5px 10px;
  }

  table {
    border: none;
    display: block;
    overflow: scroll;

    caption {
      color: $text-color;
    }

    @media screen and (max-width: $screen-md) {
      width: 640px;
    }
  }

  video {
    width: 100%;
  }

  .embed-container {
    margin-bottom: 55px;
  }

  .table-responsive {
    border: none;
    margin: 0 auto;
    max-width: 640px;
    overflow-x: auto;
  }
}

.layout--channel-show {
  .section-container {
    max-width: 1112px;
    width: 100%;
  }

  .section--news {
    padding-bottom: 64px;

    &.section--highlight {
      margin: 0 auto;
      padding: 56px 0 40px;
      width: 100%;
    }

    .card--topic {
      .card-title {
        -webkit-line-clamp: 6;
      }
    }
  }

  .section--composite {
    background-color: $brand-bright-gray;
  }

  .section--composite {
    padding-right: 0;
  }

  .section-composite.section--news {
    padding-top: 24px;
  }

  .section--clippings {
    padding: 104px 0 40px;
  }

  .section--sidebar {
    margin-top: -40px;
    padding-left: 32px;
  }

  .section--composite.section--main {
    padding-right: 0;
  }

  .section--footer {
    .section-container {
      max-width: 100%;
    }
  }

  @media screen and (max-width: $screen-md) {
    .section--news {
      &.section--highlight {
        background-color: $brand-primary;
        padding: 24px 0 40px;
      }
    }

    .section--composite {
      padding-bottom: 0;
    }

    .section--clippings {
      padding: 40px 0 56px;
    }

    .section-container {
      max-width: 100%;
    }

    .layout-hook--main {
      display: flex;
      flex-direction: column;

      .section--newsletter-homepage {
        order: 2;
      }

      .section--news {
        order: 2;

        &.section--highlight {
          order: 1;
        }
      }

      .section--contacts-homepage {
        order: 3;
      }

      .section--clippings {
        order: 4;
      }

      .section--tags {
        order: 5;
      }

      .section--features {
        order: 6;
      }

      .section--wiztrust {
        order: 7;
      }
    }
  }
}
