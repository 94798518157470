.card-action-investors {
  background-color: $brand-light;
  padding: 55px 80px;

  .card-investors-value {
    font-family: $font-family-primary;
    font-size: 18px;
  }

  .encart-title {
    font-family: $font-family-secondary;
    font-size: $font-size-xxxl;
    font-weight: $font-weight-bold;
    line-height: 49px;
    margin-bottom: 60px;
    margin-left: 25px;
    text-transform: uppercase;
  }

  .wizicon-investors-arrow-stock {
    @include size(30px, 30px);
  }

  .card-investors-last-open {
    font-family: $font-family-primary;
    font-size: 40px;
    font-weight: $font-weight-bold;
    line-height: 48px;
    text-transform: uppercase;
    margin-left: 25px;
  }

  .card-investors-equity {
    font-family: $font-family-secondary;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .stock-value {
   display: none;
   font-family: $font-family-primary;
   font-size: 18px;
   font-weight: $font-weight-bold;
   margin-left: 25px;
  }

  .card-row-datas-cells {
    margin-top: 60px;

    .card-data-cell {
      padding: 0 40px;
      text-align: center;

      .card-data-cell-up,
      .card-data-cell-down {
        padding: 0;
      }

      .card-data-cell-up > p:last-child {
        margin-bottom: 40px;
      }

      .card-data-cell-down > p:last-child {
        margin-bottom: 0;
      }
    }

    .card-data-cell:not(:last-child) {
      border-right: 1px solid #E6EAED;
    }

    .card-data-cell {
      .card-investors-title {
        color: $brand-dark;
        font-family: $font-family-primary;
        font-size: $font-size-xs;
        min-height: 30px;
        text-align: center;

        p {
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media (max-width: $grid-float-breakpoint) {
  .section--stock .card-action-investors {
    padding: 40px 20px;

    .card-row-datas-cells {
      .card-data-cell {
        border-right: none;

        .card-data-cell-up {
          border-right: 1px solid #C1C1C1;
          margin-bottom: 40px;

          p:first-child {
            margin-bottom: 15px;
          }
          p:last-child {
            margin-bottom: 0;
          }
        }

        .card-data-cell-down {
          p:last-child {
            margin-bottom: 0;
          }
        }
      }

      .card-data-cell:last-child {
        margin: 0 auto;

        .card-data-cell-up {
          border-right: 0;
        }
      }
    }
  }
}
