.label {
  line-height: 1;
  text-decoration: none;
}

.list--tags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  .label--tags {
    align-items: center;
    background-color: transparent;
    border-radius: 0;
    color: $brand-byzantium;
    display: flex;
    font-family: $font-family-medium;
    font-size: $font-size-base;
    line-height: 20px;
    padding: 0;
    text-transform: uppercase;
    white-space: normal;

    &[href]:active,
    &[href]:focus,
    &[href]:hover {
      background: transparent;
      color: $brand-byzantium;
    }
  }
}

.label--investors {
  border-radius: 0;
  border: 1px solid $brand-primary;
  color: $brand-primary;
  font-size: $font-size-xxs;
  padding: .25em .6em;
  text-transform: uppercase;
}

.card-element--tags .label--investors + .list--tags {
  display: inline-block;
}
