.breadcrumb {
  background-color: transparent;
    -moz-border-radius: none;
    -webkit-border-radius: none;
  border-radius: none;

  margin-bottom: 0;

  .breadcrumb-item:before {
    content: none; // resets bootstrap.
  }
}
