.panel-widget {
  float: left;
}

.panel-widget--collapse-indicator {
  margin-left: auto;

  .wizicon {
    transition: transform 0.3s;
    fill: $brand-white;
  }
}

.panel--web_publication_collapse.in .panel-widget--collapse-indicator .wizicon {
  transform: rotate(-180deg);
}

.in .panel-widget--collapse-indicator .wizicon {
  transform: rotate(0deg);
}

.panel--web_publication_collapse {
  border-bottom: 1px solid $brand-white;

  &:last-child {
    border-bottom: none;
  }

  .panel-heading {
    background-color: $brand-primary;
  }

  .panel-title {
    color: $brand-white;
  }

  .panel-widget--collapse-index {
    .panel-widget-item {
      @include size(59px, 59px);
      @include make-font-title--4();

      color: $brand-white;
      display: none;
      line-height: 1;
      padding: 20px;
      text-align: right;
    }
  }

  .panel-widget-item {
    padding: 16px 24px;
  }

  &.in {
    &:after {
      left: 0px;
    }

    .panel-heading,
    .panel-collapse {
      background-color: $brand-primary;
    }
  }

  &.panel--highlight {
    .panel-title,
    .panel-widget--collapse-index .panel-widget-item {
      color: $brand-white;
    }
  }

  .collapse-body {
    color: $brand-white;
    padding: 24px;
  }
}

.panel--expert_expertise {
  &:after {
    left: 0;
  }

  .panel-heading {
    background-color: $brand-bright-gray;
    padding: 16px 24px;

    a {
      gap: 40px;
    }

    @media screen and (max-width: $screen-md) {
      a {
        gap: 24px;
      }
    }
  }

  .panel-title {
    color: $brand-primary;
    flex: 1;
    font-family: $font-trenda-bold;
    font-size: $font-size-lg;
    letter-spacing: 0;
    line-height: 24px;
    padding: 0;
  }

  .panel-widget--dates {
    color: $text-color;
    flex: 0 1 0;
    float: left;
    font-family: $font-trenda-semibold;
    font-size: $font-size-base;
    letter-spacing: 0;
    line-height: 20px;

    .panel-widget-item {
      padding: 0;
    }
  }

  .panel-widget-item {
    padding: 0;
  }

  .panel-heading .wizicon-collapse-indicator {
    &.wizicon {
      fill: $brand-dark-black;
    }
  }

  .panel-body {
    padding: 24px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &.in {
    .panel-heading .wizicon-collapse-indicator {
      &.wizicon {
        -webkit-transform: rotate(180deg);
      }
    }
  }

  &:last-child:after {
    content: none;
  }

  &.panel--highlight {
    .panel-title {
      color: $brand-primary;
    }
  }
}

.panel--web_publication_collapse {
  &:after {
    left: 60px;
  }
}

.panel-group--program {
  .panel--program:last-child .panel-title {
    margin-bottom: 0;
  }
}

.panel--program {
  .panel-program--body {
    margin-bottom: 10px;
  }

  .panel-heading {
    background-color: $brand-bright-gray;
  }

  .panel-title {
    color: $brand-primary;
    padding: 16px 0;

    .panel-title-text {
      flex: 1;
      font-family: $font-trenda-bold;
      font-size: $font-size-lg;
      line-height: 24px;
      margin-left: 40px;
    }

    .panel-title--dates {
      flex-basis: 180px;
    }

    .panel-title-icon {
      .wizicon {
        fill: $brand-dark;
      }
    }

    @media screen and (max-width: $screen-md) {
      .panel-title--dates {
        flex-basis: 120px;
      }

      .panel-title-text {
        margin-left: 24px;
      }
    }
  }

  .panel-body {
    padding: 0;
  }

  .panel--subprogram {
    .panel-heading {
      .panel-title {
        align-items: center;
        display: flex;
        flex: 1;
        margin-bottom: 0;
      }

      .panel-widget--dates {
        color: $brand-primary;
        float: left;
        width: 18%;

        .panel-widget-item {
          display: flex;
          align-items: center;
        }
      }

      .panel-widget--performers {
        float: right;

        .mediaobj--performer img {
          width: 30px;
        }
      }

      .panel-widget--collapse-indicator {
        transition: transform 0.3s ease-out;

        .wizicon {
          fill: $brand-primary;
        }
      }
    }

    .panel-collapse {
      .panel-body {
        padding: 24px;

        .content-text {
          color: $text-color;
        }
      }
    }
  }

  &.in {
    .panel-title {
      .panel-title-icon {
        transform: rotate(-180deg);
        transition: transform 0.3s ease-in;
      }
    }

    .panel--subprogram.in {
      .panel-widget--collapse-indicator {
        transform: rotate(-180deg);
        transition: transform 0.3s ease-in;
      }
    }
  }
}

.panel--content-download {
  margin: 0;

  .panel-heading {
    align-items: center;
    border-radius: 100px;
    display: flex;
    height: 72px;
    justify-content: center;
    letter-spacing: normal;
    margin: 0 auto;
    min-width: 260px;
    padding: $btn-padding;
    position: relative;
    width: fit-content;
    background-color: $brand-primary;

    a.panel-title {
      align-items: center;
      display: flex;
      justify-content: center;
      transition: all 0.3s ease 0s;
      padding: 0;
    }

    .panel-title-icon {
      margin-left: 16px;
      vertical-align: middle;

      .wizicon {
        @include size(24px, 24px);
        fill: transparent;

        path {
          stroke: $brand-white;
        }
      }
    }

    .panel-title-text {
      color: $brand-white;
      font-family: $font-trenda-bold;
      font-size: $font-size-md;
      font-weight: unset;
      line-height: 20px;
      text-align: center;
      vertical-align: middle;
    }

    &:active,
    &:active:focus,
    &:focus,
    &:hover {
      background-color: $brand-blue-sky;

      .panel-title-icon {
        .wizicon {
          path {
            stroke: $brand-primary;
          }
        }
      }

      .panel-title-text {
        color: $brand-primary;
      }
    }
  }

  .panel-collapse {
    background-color: $brand-white;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2;
  }
}
