.mediaobj--performer,
.mediaobj--spokesperson {
  > .mediaobj-object {
    font-size: 20px;

    > img {
        -moz-border-radius: 40px;
        -webkit-border-radius: 40px;
      border-radius: 40px;
      border: 1px solid #eee;
      width: 40px;
    }
  }
}

.mediaobj--performer {
  .mediaobj-title {
    color: $brand-primary;
    font-weight: 600;
  }
}

.mediaobj--eventinfo {
  align-items: flex-start;
  display: flex;

  > .mediaobj-object {
    align-items: center;
    display: flex;

    .wizicon {
      @include size(24px, 24px);
      fill: $brand-light-blue;
      line-height: 1;
    }
  }

  .mediaobj-body {
    color: $text-color;
    font-family: $font-trenda-bold;
    font-size: $font-size-lg;
    line-height: 24px;
    margin-left: 8px;
    padding: 0;
  }

  .mediaobj-title {
    color: $text-color;
    font-family: $font-trenda-semibold;
    font-size: $font-size-base;
    line-height: 20px;
    margin: 0;
    padding: 0;
  }
}
