html,
body {
  position: relative;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

::selection {
  background: $brand-dark;
  color: #fff;
  text-shadow: none;
}
