.gallery--eventinfo {
  .gallery-item {
    > .card > .card-block {
      padding-bottom: 0;
      padding-top: 0;

      @media screen and (max-width: $screen-sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    .gallery-item {
      &:first-child {
        > .card > .card-block {
          padding-left: 0;
        }
      }
    }

    &:last-child {
      > .card > .card-block {
        padding-right: 0;
      }
    }

    .gallery-item + .gallery-item {
      border-left: 1px solid $card-border-color;
    }
  }
}
