.feature {
  display: block;
  margin-bottom: 10px;

  .feature-img {
    max-width: $feature-icon-width;
  }

  .feature-icon {
    background-color: $text-muted;
    border: 1px solid $feature-icon-border-color;
      -moz-border-radius: $webidentity-width;
      -webkit-border-radius: $webidentity-width;
    border-radius: $webidentity-width;
    display: block;
    height: $feature-icon-width;
    width: $feature-icon-width;

    > .wizicon {
      display: block;
      fill: #fff;
      height: 100%;
      padding: 0.20 * $feature-icon-width;
      width: 100%;
    }
  }

  .feature-text {
    display: block;
  }

  &:hover {
    .feature-icon {
      fill: $brand-primary;
    }

    .feature-text {
      color: $brand-primary;
    }
  }
}

.feature.feature--horizontal {
  .feature-img,
  .feature-icon {
    float: left;
  }

  .feature-text {
    padding: (($feature-icon-width - $line-height-computed) / 2) 15px;
    padding-left: ($feature-icon-width + 15);
  }
}

.feature.feature--vertical {
  .feature-img,
  .feature-icon {
    margin: auto;
    margin-bottom: 5px;
  }

  .feature-text {
    padding: 0;
    text-align: center;
  }
}
