﻿// Section / *
.section {
  padding: 40px 0 16px;

  &:has(.section-header) {
    padding-top: 56px;
  }

  @media screen and (max-width: $screen-md) {
    padding: 32px 0 40px;
  }
}

.section-container {
  @extend .container;

  margin: 0 auto;
  max-width: $container-lg;
  padding: 0;

  @media screen and (max-width: $screen-md) {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
}

.section-title {
  padding-bottom: $section-spacer-y * 0.66;
  position: relative;

  a {
    @extend .link-reset; // .link-default ?
  }
}

.section-block {
  padding: 0;
}

.section-header {
  padding-bottom: 0;
  padding-top: 0;
}

.section-footer {
  padding-bottom: 0;
}

.section-composite {
  margin-bottom: $section-spacer-y; // When composite, it does not get the margin.
}

.section-block-form {
  padding-bottom: $section-spacer-y - $form-group-margin-bottom;
}

.section-reset {
  padding-bottom: 0;

  @media screen and (min-width: $screen-sm-min) {
    .section-block {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.section-text--secondary {
  color: $section-text-color-muted;
}

.section-btn {
  @include button-huer(
    $brand-white, // $color,
    $brand-primary, // $color-hover,
    $brand-primary, // $bg,
    $brand-blue-sky, // $bg-hover,
    transparent, // $border-color,
    transparent, // $border-hover,
    $brand-primary // $color-active: $color,
  );

  align-items: center;
  border-radius: 100px;
  display: flex;
  height: 72px;
  justify-content: center;
  letter-spacing: normal;
  margin: 0 auto;
  min-width: 260px;
  padding: $btn-padding;
  position: relative;
  width: fit-content;

  .section-btn-icon {
    margin-left: 16px;
    vertical-align: middle;

    .wizicon {
      @include size(24px, 24px);
      fill: $brand-white;

      path {
        fill: $brand-white;
      }
    }
  }

  .section-btn-text {
    font-family: $font-trenda-bold;
    font-size: $font-size-md;
    font-weight: unset;
    line-height: 20px;
    margin-top: -3px;
    text-align: center;
    vertical-align: middle;
  }

  &:active,
  &:active:focus,
  &:focus,
  &:hover {
    background-color: $brand-blue-sky;

    .section-btn-icon {
      .wizicon {
        fill: transparent;

        path {
          fill: $brand-primary;
        }
      }
    }
  }
}

.section--jumbotron-channel {
  display: none;
  padding: 0;
  margin-bottom: 0;

  .section-container {
    padding: 0;
    width: auto;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }

    @media (min-width: $screen-md-min) {
      width: auto;
    }

    @media (min-width: $screen-lg-min) {
      width: auto;
    }
  }
}

.section--header {
  padding: 20px 0;
  margin-bottom: 0;

  .section-title {
    border: 0;
    margin-bottom: 7px;
    padding: 0;

    font-size: 16px; // @TODO
    font-weight: 600;
    text-transform: none;
    text-align: center;
  }

  .section-text {
    text-align: center;
  }
}

.layout-hook--header {
  .section.section--header {
    padding: 0;

    > .section-container {
      max-width: $container-main;
      padding: 24px 66px;
      width: 100%;
    }

    .section-wrapper {
      position: unset;

      .menu-desktop {
        align-items: center;
        display: flex;
        position: unset;
        width: 100%;
      }

      .section-img {
        align-items: center;
        display: flex;
        height: 56px;
        margin: 0;
        max-width: 216px;
        width: 100%;

        img {
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          width: 100%;
        }
      }

      .header-site-title a {
        color: $brand-primary;
        display: inline-block;
        font-family: $font-trenda-bold;
        font-size: $font-size-md;
        line-height: 20px;
        margin-top: 4px;
        padding: 0 0 0 32px;
        text-transform: uppercase;
      }

      .section-block {
        flex: 1;
        position: unset;

        .header-beamer-wrapper {
          .section-container {
            align-items: center;
            display: flex;
            margin-right: 0;
            padding: 0;
            width: 100%;

            .header-menu-close {
              display: none;
            }

            .header-container {
              margin: 0 auto;
            }

            .header-main {
              > ul {
                align-items: center;
                display: flex;
                margin: 0;
                padding: 0;

                li.nav-item {
                  line-height: normal;
                  list-style: none;
                  padding: 0 20px;

                  &.nav-item--map {
                    .offie-dropdown {
                      > .offie-block {
                        background-color: $brand-white;
                      }
                    }

                    .offie-dropdown--1 {
                      background-color: $brand-white;

                      > .offie-block {
                        background: url('../../../../images/map.png') no-repeat;
                        background-color: $brand-white;
                        padding: 0 48px;

                        > .nav--channel-2 {
                          .nav-item.js-offie {
                            > .nav-link {
                              pointer-events: none;

                              .nav-link-text {
                                font-family: $font-trenda-bold;
                                font-size: $font-size-xlg;
                                padding-left: 0;

                                &::before {
                                  content: none;
                                }
                              }
                            }

                            @media screen and (max-width: $screen-md) {
                              > .nav-link {
                                margin-bottom: 8px;

                                .nav-link-text {
                                  font-family: $font-family-bold;
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    .nav--channel-2 {
                      flex-direction: column;
                      padding-bottom: 20px;

                      > li.nav-item {
                        width: 100%;

                        &:first-child {
                          padding: 20px 0;
                          pointer-events: none;

                          .nav-link {
                            .nav-link-text {
                              font-family: $font-trenda-bold;
                              padding-left: 0;
                              white-space: normal;

                              &::before {
                                content: none;
                              }

                              @media screen and (max-width: $screen-md) {
                                font-family: $font-family-bold;
                                font-size: $font-size-xlg;
                              }
                            }
                          }
                        }

                        .offie-dropdown.offie-dropdown--2 {
                          opacity: 1;
                          position: relative;
                          top: 0;
                          transition-delay: 0s;
                          visibility: visible;

                          .offie-block {
                            background-color: transparent;
                            border: none;
                            box-shadow: none;
                            padding-left: 0;

                            .nav.nav--channel-2 {
                              columns: 4;
                              display: list-item;
                              max-width: 100%;

                              li.nav-item {
                                a.nav-link:hover,
                                a.nav-link:focus {
                                  background-color: transparent;

                                  .nav-link-text {
                                    font-family: $font-trenda-bold;
                                  }
                                }

                                &:first-child {
                                  padding: 0;
                                  pointer-events: auto;

                                  .nav-link {
                                    .nav-link-text {
                                      &::before {
                                        -webkit-transform: translate(0,-50%) rotate(270deg);
                                        background-image: url('../../../../images/svgicons/icon-arr-down.svg');
                                        background-repeat: no-repeat;
                                        content: "";
                                        height: 24px;
                                        left: 0;
                                        padding-right: 0;
                                        position: absolute;
                                        top: 51%;
                                        width: 24px;
                                      }
                                    }
                                  }
                                }

                                .nav-link {
                                  padding: 5px 15px;
                                  white-space: normal;

                                  .nav-link-text {
                                    font-family: $font-trenda-light;
                                    font-size: $font-size-base;
                                    padding-left: 20px;
                                  }
                                }

                                @media screen and (max-width: $screen-md) {
                                  .nav-link {
                                    padding: 8px 16px;
                                  }

                                  &:first-child {
                                    .nav-link {
                                      .nav-link-text {
                                        &::before {
                                          content: none;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  .offie-dropdown--1 {
                    background-color: $brand-bright-gray;

                    > .offie-block {
                      background-color: transparent;
                      margin: 0 auto;
                      max-width: $container-main;
                      width: 100%;
                    }

                    @media screen and (max-width: $screen-md) {
                      background-color: transparent;
                    }
                  }

                  .offie-dropdown {
                    left: 0;
                    top: 104px;
                    width: 100%;

                    .offie-block {
                      border: none;
                      box-shadow: none;
                      display: none;
                      padding: 16px 28px;
                    }

                    li.nav-item {
                      a.nav-link:hover,
                      a.nav-link:focus {
                        background-color: transparent;

                        .nav-link-text {
                          font-family: $font-trenda-bold;
                        }

                        @media screen and (max-width: $screen-md) {
                          .nav-link-text {
                            font-family: $font-family-bold;
                          }
                        }
                      }
                    }

                    &.offie--active {
                      .offie-block {
                        display: block;
                      }
                    }
                  }

                  .nav--channel-2 {
                    display: flex;
                    flex-wrap: wrap;

                    li.nav-item {
                      padding: 0;

                      .nav-link {
                        padding: 10px 20px;
                      }

                      .nav-link-text {
                        padding-left: 20px;
                        position: relative;

                        &::before {
                          -webkit-transform: translate(0, -50%) rotate(270deg);
                          background-image: url('../../../../images/svgicons/icon-arr-down.svg');
                          background-repeat: no-repeat;
                          content: "";
                          height: 24px;
                          left: 0;
                          padding-right: 0;
                          position: absolute;
                          top: 51%;
                          width: 24px;
                        }

                        &::after {
                          content: none;
                        }

                        @media screen and (max-width: $screen-md) {
                          font-family: $font-family-light;
                          font-size: $font-size-base;
                          line-height: 24px;
                          padding-left: 0;

                          &::before {
                            content: none;
                          }
                        }
                      }
                    }
                  }

                  &.js-offie {
                    .nav-link {
                      .nav-link-text {
                        &::after {
                          -webkit-transform: translate(0, -50%);
                          background-image: url('../../../../images/svgicons/icon-arr-down.svg');
                          background-repeat: no-repeat;
                          content: "";
                          height: 24px;
                          position: absolute;
                          top: 51%;
                          width: 24px;
                        }
                      }
                    }
                  }

                  &.active {
                    > .nav-link {
                      color: $brand-byzantium;
                    }
                  }

                  .nav-link {
                    color: $brand-primary;
                    display: inline-block;
                    font-family: $font-trenda-bold;
                    font-size: $font-size-base;
                    line-height: 20px;

                    .nav-link-icon {
                      display: none;
                      position: absolute;
                      top: 4px;
                    }

                    .wizicon {
                      color: $brand-secondary;
                      fill: $brand-secondary;
                      height: 6.5px;
                      width: 8px;
                    }
                  }
                }
              }
            }

            .header-language {
              position: relative;

              .language-item,
              .language-link {
                color: $brand-primary;
                font-family: $font-trenda-bold;
                font-size: $font-size-base;
                line-height: 20px;

                @media screen and (max-width: $screen-md) {
                  font-family: $font-family-bold;
                }
              }

              .language-link-icon {
                .wizicon {
                  -webkit-transform: rotate(0);
                  -webkit-transition: all ease .3s;
                  height: 24px;
                  width: 24px;

                  path {
                    fill: $brand-primary;
                  }
                }
              }

              .active-lang {
                cursor: pointer;

                &.language-item {
                  align-items: center;
                  display: flex;
                  font-family: $font-trenda-bold;
                  position: relative;

                  @media screen and (max-width: $screen-md) {
                    font-family: $font-family-bold;
                  }
                }
              }

              ul {
                -webkit-transition: all ease .3s;
                display: none;
                list-style: none;
                margin: 0;
                padding: 0;
                position: absolute;
                top: calc(100% + 3px);
                width: 100%;

                .language-link {
                  color: $brand-primary;
                }
              }

              &.active {
                .language-link-icon {
                  .wizicon {
                    -webkit-transform: rotate(180deg) translate(0, -3px);
                  }
                }

                ul {
                  display: block;
                }
              }

              @media screen and (max-width: $screen-md) {
                border-right: 1px solid $brand-bright-gray;
                margin-right: 8px;
                padding-right: 8px;
              }
            }

            .flux-rss {
              color: $brand-white;
              font-size: $font-size-base;
              line-height: 24px;
              min-width: 80px;
              text-align: center;

              a {
                background: $brand-light-blue;
                border-radius: 4px;
                color: $brand-white;
                display: block;
                padding: 4px 13px 4px 12px;
              }
            }

            .nav-item--search {
              position: relative;

              .form--clientsearch {
                .input-group {
                  -webkit-transform: translate(0,-50%);
                  -webkit-transition: all ease .5s;
                  opacity: 0;
                  position: absolute;
                  right: 27px;
                  top: 50%;
                  width: 0;
                }

                &.active {
                  .input-group {
                    opacity: 1;
                    width: 230px;
                    z-index: 2;
                  }

                  .form-control {
                    padding: 12px 45px 12px 16px;
                  }

                  .input-group-btn {
                    display: block;
                    width: auto;

                    .btn-default {
                      background-color: transparent;
                      padding: 10px 16px;
                    }
                  }

                  @media screen and (max-width: $screen-md) {
                    .input-group {
                      width: 50vw;
                    }
                  }
                }

                .form-control {
                  border-radius: 3px;
                  border: 1px solid $brand-primary;
                  color: $brand-primary;
                  padding: 0;
                }

                .input-group-btn {
                  -webkit-transform: translate(0,-50%);
                  display: none;
                  position: absolute;
                  right: 1px;
                  top: 50%;
                  width: 0;
                  z-index: 3;

                  .btn-default {
                    border-radius: 4px;
                    border: none;
                    padding: 0;

                    &:hover {
                      background-color: $brand-white;
                    }

                    .wizicon {
                      height: 16px;
                      width: 16px;
                    }
                  }
                }
              }

              .btn-toggle-box {
                cursor: pointer;
                padding-left: 16px;

                .icon-search,
                .icon-arr {
                  display: none;

                  &.active {
                    display: block;
                  }

                  .wizicon {
                    height: 16px;
                    width: 16px;
                  }
                }

                .icon-arr {
                  -webkit-transform: rotate(270deg);
                }

                @media screen and (max-width: $screen-md) {
                  padding-left: 0;
                }
              }
            }

            .section-container.menu-mo {
              display: none;
            }
          }
        }
      }
    }

    .menu-mobile {
      display: none;
      width: 100%;

      .section-block {
        .header-beamer-wrapper {
          .section-container {
            flex-direction: column;

            .block-menu-top {
              align-items: center;
              display: flex;
              justify-content: center;
              padding: 12px 16px;
              position: relative;
              width: 100%;

              .section-img {
                padding-right: 0;

                img {
                  height: 40px;
                  object-fit: cover;
                  padding: 0;
                  width: 154px;
                }
              }

              .header-language {
                -webkit-transform: translate(0, -50%);
                margin-right: 0;
                position: absolute;
                right: 16px;
                top: 50%;
              }
            }

            .block-menu-second {
              align-items: center;
              background-color: transparent;
              display: flex;
              padding: 16px 0 16px 16px;
              position: relative;
              width: 100%;
              z-index: 2;

              .header-burger {
                align-items: flex-end;
                display: flex;
                flex-direction: column;
                margin-left: auto;
                padding: 16px;

                .icon-buger {
                  background: $brand-primary;
                  height: 2px;
                  width: 24px;

                  &:first-child {
                    margin-bottom: 4px;
                    width: 16px;
                  }
                }

                &.active {
                  .icon-buger {
                    &:first-child {
                      width: 24px;
                      -webkit-transform: rotate(45deg);
                    }

                    &:last-child {
                      -webkit-transform: rotate(-45deg) translate(4px, -4px);
                    }
                  }
                }
              }

              .header-site-title {
                a {
                  border-left: none;
                  color: $brand-white;
                  font-size: $font-size-lg;
                  line-height: 24px;
                  padding: 0;
                }
              }
            }

            .block-menu-detail {
              -webkit-transition: all ease .5s;
              background: $brand-white;
              bottom: 0;
              left: -100%;
              position: fixed;
              width: 100vw;
              z-index: -1;
              min-height: calc((var(--vh, 1vh) * 100) - 88px);

              &.active {
                left: 0;
                z-index: 9;
              }

              .header-container {
                height: calc((var(--vh, 1vh) * 100) - 120px);
                overflow: scroll;
                padding: 32px 16px;

                .header-main {
                  display: flex;
                  flex-direction: column;
                  height: 100%;

                  .group-mask {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    margin-top: auto;
                  }

                  > ul {
                    align-items: flex-start;
                    flex-direction: column;
                    position: relative;
                    width: 100%;

                    li.nav-item {
                      width: 100%;
                      padding: 0;

                      .nav-link {
                        color: $brand-primary;
                        display: flex;
                        font-family: $font-family-bold;
                        font-size: $font-size-xl;
                        line-height: 32px;
                        margin: 0 0 16px;
                        position: relative;
                        width: 100%;

                        .nav-link-icon {
                          display: inline-block;
                          margin-left: auto;
                          position: static;

                          .wizicon {
                            -webkit-transform: rotate(0);
                            -webkit-transition: all ease .2s;
                            color: $brand-primary;
                            fill: $brand-primary;
                            height: 24px;
                            width: 24px;

                            path {
                              fill: $brand-primary;
                            }
                          }
                        }

                        .nav-link-text {
                           &::after {
                            content: none;
                          }
                        }

                        &.offie--active {
                          .nav-link-icon {
                            .wizicon {
                              -webkit-transform: rotate(90deg);
                            }
                          }
                        }
                      }

                      .offie-block {
                        background: transparent;
                        padding: 0 0 0 16px;

                        .nav--channel-2 {
                          flex-direction: column;

                          li.nav-item {
                            padding: 0;
                            width: 100%;

                            .nav-link {
                              flex-direction: column;
                              padding: 0;
                              width: 100%;

                              > .nav-link-icon {
                                display: none;
                              }
                            }

                            &:last-child {
                              .offie-dropdown--2 {
                                margin-bottom: 0;
                              }
                            }

                            .offie-dropdown--2 {
                              margin-bottom: 20px;

                              .offie-block {
                                padding: 0;

                                .nav.nav--channel-2 {
                                  display: flex;
                                  padding-bottom: 0;

                                  li.nav-item {
                                    .nav-link {
                                      margin-bottom: 0;

                                      .nav-link-text {
                                        font-family: $font-family-light;
                                        line-height: 24px;
                                        padding: 0;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  .flux-rss {
                    a {
                      padding: 8px 13px 8px 12px;
                    }
                  }

                  .close-menu-btn {
                    display: flex;
                    flex-direction: column;
                    height: 40px;
                    position: absolute;
                    right: 24px;
                    top: 24px;
                    width: 40px;

                    .icon-buger-close {
                      background-color: $brand-primary;
                      height: 3px;
                      width: 100%;

                      &:first-child {
                        -webkit-transform: translate(0px,17px)rotate(45deg);
                      }

                      &:last-child {
                        -webkit-transform: translate(0px,14px)rotate(-45deg);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: $screen-md) {
      > .section-container {
        border-bottom: none;
        padding: 0;
      }

      .menu-mobile {
        display: block;
      }

      .section-wrapper {
        .menu-desktop {
          display: none;
        }
      }
    }
  }
}

.section--header-2 {
  .section-img {
    > img {
      margin-left: 0;
    }
  }

  .section-title,
  .section-text {
    text-align: left;
  }
}

.section--header-3 {
  .section-title,
  .section-text {
    text-align: left;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    .section-img {
      margin-left: 0;
      width: 50%;

      > img {
        margin-left: 0;
      }
    }

    .section-block {
      bottom: 0px;
      position: absolute;
      right: 0;

    }

    .section-title,
    .section-text {
      text-align: right;
    }
  }
}

.section--news.section--highlight {
  .section-block {
    padding: 0;
  }
}

.section-header.section-header--with-options {
  @include clearfix();
  padding-bottom: 0;

  .section-header-widget {
    display: none;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: none;
      float: right;
    }
  }
}

.layout-index {
  .section--composite {
    padding: 0;
  }

  .section-footer {
    display: flex;
    justify-content: center;
    padding-top: 0;
  }

  .pagination {
    align-items: center;
    display: flex;
    padding-bottom: 32px;
    padding-top: 16px;

    .wizicon {
      &-arr-paging-right,
      &-arr-paging-left {
        width: 7px;
      }
    }

    > li {
      display: inline-block;

      &.active {
        padding: 0 10px;
      }

      & + li {
        margin-left: 15px;
      }

      > a,
      > span {
        border: none;
        line-height: 0;
        padding: 0;
      }
    }
  }

  .section--events {
    padding-bottom: 56px;
  }

  @media screen and (max-width: $screen-md) {
    .section--events {
      padding-bottom: 40px;
    }
  }
}

.section--jumbotron-view {
  background-color: $brand-primary;
  background-image: url("../../../../images/bgk-line-separation.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 361px 100%;
  padding: 80px 0;

  .section-title {
    border: none;
    color: $brand-blue-sky;
    font-family: $font-trenda-bold;
    font-size: $font-size-page-title;
    line-height: 72px;
    margin-bottom: 0;
    padding: 0;
    text-transform: uppercase;

    &::before {
      display: none;
    }
  }

  .section-description {
    color: $brand-white;
    font-family: $font-trenda-bold;
    font-size: $font-size-lg;
    line-height: 24px;
    margin-top: 24px;

    p {
      margin: 0;
    }
  }
}

@media screen and (max-width: $screen-md) {
  .section--jumbotron-view {
    background-image: none;
    padding: 40px 0;

    .section-block {
      .section-title {
        font-size: $font-size-xxxl-mb;
        line-height: 48px;
      }
    }
  }
}

.layout--video-show {
  .section--jumbotron-content.section--jumbotron-asset {
    .section-element--content {
      display: block;
    }
  }
}

.layout--video-show {
  .section--jumbotron-content.section--jumbotron-asset {
    .section-element--content {
      display: block;
    }
  }
}

.layout-index {
  &.layout--asset-index {
    .section--files,
    .section--folders,
    .section--photos,
    .section--videos {
      &:has(.section-btn) {
        padding-bottom: 56px;

        .section-footer {
          margin-top: 16px
        }
      }
    }

    .section--files {
      padding-bottom: 16px;
    }

    .section {
      &:not(.section--jumbotron-view) {
        &.section--even {
          background-color: $brand-white;
        }

        &.section--odd {
          background-color: $brand-bright-gray;
        }
      }
    }

    .layout-hook--main {
      padding-bottom: 80px;
    }
  }

  &.layout--event_web_publication-index {
    .section-events-to-come {
      .section-footer {
        .pagination {
          padding-bottom: 0;
          padding-top: 32px;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    &.layout--asset-index {
      .layout-hook--main {
        padding-bottom: 40px;
      }
    }
  }
}

.section-composite {
  position: relative;
}

.section--boilerplate {
  padding: 40px 0;
  margin-bottom: 0;

  .section-img {
    margin-bottom: 10px;

    img {
      margin: auto;
      display: block;
      width: 200px;
    }
  }

  .section-title {
    @include make-font-10();
    border-bottom: 0;
  }

  .section-block {
    @include make-font-1();
    padding-top: 0;

    @media screen and (min-width: $grid-float-breakpoint) {
      @include make-columns(2, 2em);
    }
  }
}

.section-languages {
  text-align: right;

  .section-header-widget-item {
    display: inline-block;
  }
}

.section--newsletter-contact,
.section--newsletter-form {
  padding: 48px 0 18px;

  @media (max-width: $screen-md) {
    &.section {
      padding: 32px 0 18px;
    }
  }
}

.section--newsletter-contact {
  .jumbotron-body {
    display: flex;
    justify-content: space-between;

    .jumbotron-contact {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 64px;
      padding-left: 0;

      .contact-title,
      .webidentities {
        color: $brand-primary;
        font-family: $font-family-bold;
        font-size: $font-size-lg;
        line-height: 24px;
        padding-top: 48px;
      }

      .contact-title:nth-child(1) {
        padding-top: 0px;
      }

      .contact-cta a,
      .social-identity,
      .contact-email,
      .contact-phone {
        color: $brand-primary;
        font-family: $font-family-light;
        font-size: $font-size-md;
        line-height: 40px;
      }

      .contact-cta {
        padding-top: 8px;

        .wizicon {
          -webkit-transform: rotate(-90deg);
          height: 24px;
          margin-top: -3px;
          width: 24px;
        }
      }

      .contact-email,
      .contact-phone {
        display: none;
      }

      .list--webidentities {
        margin: 0 -8px;

        .list-item {
          margin-bottom: 0;
          padding: 8px 8px 0;
        }
      }
    }

    .jumbotron-img {
      flex-basis: 514px;
      max-width: 514px;
      width: 100%;

      img {
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .webidentity {
    background-color: $brand-primary;
    border: 1px solid $brand-primary;

    .wizicon {
      .cls-1 {
        fill: #fff;
      }
    }
  }

  @media (max-width: $screen-md) {
    .jumbotron-body {
      flex-direction: column;

      .jumbotron-contact {
        flex: auto;
        margin: 0;

        .contact-title,
        .webidentities {
          padding-top: 24px;
        }
      }

      .jumbotron-img {
        flex-basis: auto;
        margin: 24px -16px 0;
        max-width: unset;
        width: calc(100% + 32px);
      }
    }
  }
}

.section--newsletter-form,
.section--contactform {
  background-color: $brand-white;

  .section-container {
    background-color: $brand-bright-gray;
  }

  .section-wrapper {
    background-color: $brand-bright-gray;
    padding: 48px 96px;

    .section-header {
      &:before {
        display: none;
      }

      .section-title {
        color: $text-color;
        font-family: $font-family-bold;
        font-size: 24px;
        line-height: 24px;
        padding-bottom: 32px;
        text-align: left;
        text-transform: none;
      }
    }

    .section-block {
      form {
        label {
          color: $text-color;
          font-family: $font-family-light;
          font-size: $font-size-lg;
          font-weight: unset;
          line-height: 24px;
          margin-bottom: 8px;
        }

        .form-control {
          border-radius: 0;
          border: 0;
          color: $text-color;
          font-family: $font-family-light;
          font-size: $font-size-base;
          padding: 12px 24px;

          &::-webkit-input-placeholder {
            color: $text-color;
          }
        }

        .form-group {
          margin-bottom: 32px;

          &.google-captcha-field {
            .help-block {
              margin-bottom: 30px;
            }
          }
        }

        .form-group--eula,
        .form-group--subscribe {
          margin-bottom: 32px;
          padding-left: 0;

          input[type="checkbox"] {
            border: 1px solid $brand-dark-black;
            height: 12px;
            margin-top: 4px;
            width: 12px;

            &::before {
              background: $brand-bright-gray;
              border: 1px solid $brand-dark-black;
              content: "";
              display: block;
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            }

            &:checked {
              &::before {
                background: $brand-dark-black;
              }

              &::after {
                background: $brand-dark-black;
                color: $brand-white;
                content: "\2713";
                display: block;
                font-size: 10px;
                font-weight: 700;
                height: 100%;
                left: 0;
                padding-left: 20%;
                position: absolute;
                top: 0;
                width: 100%;
              }
            }
          }
        }

        .checkbox {
          margin: 0;

          label {
            color: $text-color;
            font-size: $font-size-md;
            line-height: 20px;
            margin: 0;

            p:last-child {
              margin-bottom: 0;
            }
          }

          input + label {
            padding: 0;
          }
        }

        .help-block {
          font-family: $font-family-light;
        }

        .form-submit,
        .section-btn {
          @include make-block-center(unset);
        }

        .section-btn {
          height: unset;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    .section-wrapper {
      padding: 32px 0;

      .section-header {
        .section-title {
          font-size: $font-size-xxl;
          line-height: 32px;
        }
      }

      .section-block {
        form {
          .form-control,
          .help-block {
            margin-bottom: 20px;
          }

          .form-group--eula,
          .form-group--subscribe {
            margin-bottom: 20px;
            padding-left: 0;
          }
        }
      }
    }
  }
}

.section--newsletter-form {
  .form--newsletter {
    .form--subject {
      .form-group.has-error {
        display: flex;
        flex-direction: column;

        #front_newsletter_type_newsletterSubject-error {
          order: 1;
        }
      }

      .wiztopic-select-container {
        background-color: transparent;
        margin: 24px 0 0 4px;
        position: static;
        width: 100%;
        z-index: unset;

        &.wiztopic-select-is-open {
          border: none;
          padding: 0;
        }

        .wiztopic-select-body {
          background-color: transparent;
          display: block;
          margin-left: 0;

          .wiztopic-select-body-wrap {
            .wiztopic-select-item {
              padding: 0;

              .wiztopic-select-item-label {
                color: $text-color;
                font-family: $font-family-light;
                font-size: $font-size-md;
                font-weight: unset;
                line-height: 20px;
              }

              .wiztopic-select-item-mark {
                background-color: $brand-bright-gray;
                border-radius: 0;
                border: 1px solid $brand-dark;
                display: inline-block;
                height: 12px;
                margin-top: -1px;
                width: 12px;

                &::before {
                  color: $brand-white;
                  font-size: $font-size-xxs;
                  font-weight: unset;
                  height: 100%;
                  left: 0;
                  padding-left: 10%;
                  position: absolute;
                  top: -1px;
                  transition: none;
                  width: 100%;
                }
              }

              &.wiztopic-select-item-selected {
                .wiztopic-select-item-mark {
                  background: $brand-dark-black;

                  &::before {
                    transform: scale(1);
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: $screen-md) {
          margin: 12px 0 20px;

          .wiztopic-select-body {
            margin-left: 0;
          }
        }
      }

      .wiztopic-select-header {
        display: none;
      }
    }
  }
}

.section--newsletter-homepage {
  background-color: $brand-cultured;
  padding: 24px 0;

  .section-block {
    align-items: center;
    display: flex;

    .section-btn {
      flex-basis: 314px;
      margin: 0;
    }

    .block--webidentities {
      align-items: center;
      display: flex;
      flex: 1;
      margin-left: auto;
      padding-left: 24px;

      > .block-title {
        color: $brand-primary;
        flex: 1;
        font-family: $font-trenda-bold;
        font-size: $font-size-xxl;
        line-height: 32px;
        margin: 0;
      }

      > .list {
        flex-basis: 248px;
        flex-wrap: wrap;
        padding-left: 24px;
        row-gap: 8px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .section-block {
      align-items: center;
      flex-direction: column-reverse;

      .section-btn {
        flex-basis: auto;
      }

      .block--webidentities {
        flex-direction: column;
        margin: 0 0 24px 0;
        padding-left: 0;

        > .block-title {
          font-size: $font-size-xlg;
          margin-bottom: 24px;
          text-align: center;
        }

        > .list {
          flex-basis: auto;
          padding: 0;
        }
      }
    }
  }
}

.layout-hook--footer .section--newsletter {
  background-image: url('../../../../images/events_background.png');
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid $border-color;
  margin-bottom: 0;

  .section-title {
    @include make-font-10();
    border-bottom: none;
    text-align: left;
  }

  .layout--newsletter-index & {
    display: none;
  }
}

.section.section--contact-footer {
  background-color: $brand-bright-gray;
  padding: 80px 0;

  .section-container {
    max-width: $container-main;
    width: 100%;
  }

  .section-wrapper {
    .section-header {
      .section-title {
        padding-bottom: 56px;
      }
    }
  }

  .section-block {
    display: flex;

    .mask-box-img {
      flex-basis: 714px;

      .section-img {
        @include image-view(714, 488);
        border-radius: 4px;
        overflow: hidden;
      }
    }

    .section-content {
      flex: 1;
      padding: 40px 200px 24px 40px;

      > .block-title {
        color: $brand-primary;
        font-family: $font-trenda-bold;
        font-size: $font-size-xxl;
        line-height: 32px;
        margin: 0;
        padding-bottom: 24px;
      }

      .block-information {
        padding-bottom: 32px;

        .card-btn {
          all: unset;
          align-items: center;
          cursor: pointer;
          display: flex;

          .card-btn-icon {
            margin: 0;

            .wizicon {
              -webkit-transform: rotate(-90deg);
              -webkit-transition: all ease .3s;
              margin-top: -10px;
            }
          }

          .card-btn-text {
            color: $brand-primary;
            font-family: $font-family-light;
            font-size: $font-size-md;
            line-height: 40px;
          }

          &.rotated {
            .card-btn-icon {
              .wizicon {
                -webkit-transform: rotate(0);
              }
            }
          }
        }

        .card-text {
          color: $brand-primary;
          font-family: $font-family-light;
          font-size: $font-size-md;
          line-height: 20px;
          padding-left: 8px;

          .wizicon {
            fill: $brand-primary;
          }
        }
      }

      .section-btn {
        margin: 0;
      }

      .block--webidentities {
        margin-top: 72px;

        .block-title {
          color: $brand-primary;
          font-family: $font-trenda-bold;
          font-size: $font-size-xxl;
          line-height: 32px;
          margin-bottom: 24px;
          padding: 0;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    padding: 40px 0;

    .section-wrapper {
      .section-header {
        .section-title {
          padding-bottom: 24px;
        }
      }
    }

    .section-block {
      flex-direction: column;

      .mask-box-img {
        display: none;
      }

      .section-content {
        padding: 0;

        .section-btn {
          margin: 0 auto;
        }

        .block--webidentities {
          margin-top: 56px;

          > .block-title {
            font-size: $font-size-xlg;
          }
        }
      }
    }
  }
}

.section--main,
.section--sidebar {
  margin-bottom: 0;
}

.section--jumbotron-content {
  margin: 0;
  padding: 40px 0; // @TODO

  .section-background {
    display: none;
  }

  .section-element {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;

    .alway-first-tag {
      margin-bottom: 40px;

      .list--tags {
        .list-item {
          .label--tags {
            border-radius: 100px;
            border: 2px solid $brand-primary;
            color: $brand-primary;
            font-family: $font-trenda-bold;
            line-height: 16px;
            padding: 16px 24px;
            text-transform: unset;
          }
        }
      }
    }
  }

  .section-title {
    @include make-font-title--1();

    border-bottom: none;
    margin-bottom: 40px;
    padding-bottom: 0;
    text-transform: none;
    word-break: break-word;
  }

  .section-subtitle {
    color: $brand-primary;
    font-family: $font-family-medium;
    font-size: 16px;
    font-weight: unset;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 0;
    text-transform: uppercase;
    word-wrap: break-word;
  }

  .section-element--list {
    align-items: center;
    display: flex;
    margin-bottom: 40px;

    .section-element--info {
      align-items: center;
      display: flex;
      margin: 26px auto auto 0;
      padding-right: 16px;

      .section-element--tags {
        flex: 1;

        .list--tags {
          margin: 0 -10px;

          .list-item {
            padding: 0 10px;
            position: relative;

            & + .list-item:before {
              -webkit-transform: translate(0, -50%);
              content: "-";
              left: -3px;
              position: absolute;
              top: 50%;
            }

            .label--tags {
              text-align: left;
            }
          }
        }
      }

      .dash {
        margin: 0 8px;
      }
    }
  }

  .share.share--default {
    .share-title {
      color: $text-color;
      display: block;
      font-family: $font-trenda-light;
      font-size: $font-size-md;
      font-weight: unset;
      line-height: 20px;
      margin-bottom: 8px;
      text-transform: none;
      width: max-content;
    }

    .btn-close-share,
    .icon-share {
      display: none;
    }

    .list--share {
      display: flex;
      flex-direction: column;
    }

    .list-item {
      margin: 0;

      .share-item {
        background-color: $brand-primary;
        border-radius: 100rem;
        display: block;
        height: 32px;
        position: relative;
        width: 32px;

        .wizicon {
          &[class*="wizicon-"] {
            background-color: transparent;
            border-radius: unset;
            border: none;
            fill: $brand-white;
            height: 18px;
            left: 50%;
            padding: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 18px;
          }
        }
      }

      & + .list-item {
        margin: 8px 0 0;
      }
    }
  }

  .section-img {
    @include image-view(16,9);

    margin-bottom: 24px;
    max-width: unset;
    width: 100%;
  }

  .section-text {
    color: $brand-primary;
    font-size: $font-size-lg;
    line-height: 24px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .section-highlight-text {
    font-family: $font-trenda-bold;
    font-size: $font-size-xl;
    line-height: 32px;
    margin-top: 40px;

    a {
      color: $brand-byzantium;
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  &.section--jumbotron-expert {
    .section-title {
      display: flex;
      flex-direction: column;
    }

    .section-subtitle {
      color: $text-color;
      font-family: $font-trenda-bold;
      font-size: $font-size-lg;
      line-height: 24px;
      text-transform: unset;
    }
  }

  @media screen and (min-width: $screen-md) {
    .js-sharing-affix {
      display: block;
      padding: 0;
      position: absolute; // Positioned relative to the section-wrapper.
      right: -133px;
      top: 160px;

      &.affix {
        position: fixed;
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    padding: 8px 0 16px;

    .overlay-share {
      display: none;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 51;

      &.active {
        display: block;
      }
    }

    .section-element {
      .alway-first-tag {
        margin-bottom: 16px;
      }

      &.section-element--download {
        display: none;
      }
    }

    .section-title {
      font-size: $font-size-xxl;
      line-height: 40px;
      margin-bottom: 16px;
    }

    .section-element--list {
      align-items: flex-start;
      flex-direction: column;

      .section-element--info {
        margin: 0;
        padding-right: 0;

        .section-element--tags {
          .list--tags {
            .list-item + .list-item:before {
              content: none;
            }
          }
        }
      }
    }

    .section-img {
      margin: 0 -16px;
      padding-top: calc(((100% / 16) * 9) + 32px);
      width: calc(100% + 32px);
    }

    .section-highlight-text {
      font-size: $font-size-lg;
      line-height: 24px;
      margin-top: 24px;
    }

    .share.share--default {
      bottom: 10px;
      display: none;
      left: 10px;
      padding: 0;
      position: fixed;
      right: auto !important;
      top: auto !important;
      z-index: 60;

      .share-title {
        display: none;
      }

      .icon-share {
        background: $brand-queen-blue-light;
        border-radius: 50%;
        display: block;
        padding: 6px;

        .wizicon {
          height: 24px;
          padding-right: 4px;
          width: 24px;
        }
      }

      .list--share {
        display: none;
        margin-bottom: 0;
        margin-left: 0;

        &.active {
          background-color: $brand-light;
          border-radius: 30px;
          display: block;
          padding: 10px 10px;
        }

        .list-item {
          margin: 0 0 0 0;
          display: block;

          +.list-item {
            margin-top: 10px;
          }
        }

        .btn-close-share {
          color: $brand-light-blue;
          display: block;
          font-size: 30px;
          line-height: 30px;
          margin-top: 5px;
        }

        .share-item {
          align-items: center;
          display: flex;
          height: 30px;
          justify-content: center;
          line-height: 15px;
          width: 30px;

          .wizicon {
            padding: 0;
          }
        }
      }
    }
  }
}

.section--jumbotron-content.section--jumbotron-folder {
  .content-text {
    font-family: $font-trenda-bold;
  }
}

.section--jumbotron-content.section--jumbotron-asset {
  .section-subtitle {
    color: $brand-dark-black;
    font-size: $font-size-lg;
    font-family: $font-trenda-semibold;
    line-height: 24px;
    text-transform: unset;
  }

  .section-element--list {
    align-items: center;
    display: flex;
    margin-bottom: 88px;

    .section-element--download {
      margin: 0 0 0 auto;
    }
  }

  .section-element--content {
    align-items: flex-end;
    flex-direction: column;

    .center-block {
      margin: 0;
      width: 100%;
    }
  }

  & ~ .layout-hook--content:not(:has(.section-text)) {
    display: none;
  }

  @media screen and (max-width: $screen-md) {
    .section-element--list {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 40px;

      .section-element--download {
        display: flex;
        margin: 24px auto 0;
      }
    }

    .section-element--content {
      margin: 0 -16px;

      .copyright {
        padding-right: 16px;
      }
    }
  }
}

.layout--asset-show {
  .section--jumbotron-content.section--jumbotron-asset {
    .section-subtitle {
      padding-right: 24px;

      @media screen and (max-width: $screen-md) {
        padding: 0;
      }
    }
  }
}

.layout {
  .datetimepicker {
    table {
      tr {
        td {
          span.active.active {
            background: $brand-secondary;

            &:hover {
              background: $brand-secondary;
            }
          }

          span.active {
            &:hover {
              background: $brand-secondary;
            }
          }
        }

        td {
          &.active {
            &.active {
              background: $brand-secondary;
            }

            &:active,
            &:hover.active {
              background: $brand-secondary;
            }

            &:hover {
              &:active {
                background: $brand-secondary;
              }
            }
          }
        }
      }
    }
  }
}

.layout--index {
  &.layout-search {
    .gallery {
      &.row {
        margin-left: -16px;
        margin-right: -16px;
      }

      .gallery-item {
        margin-bottom: 32px;

        &[class*="col-"] {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }

    .section-footer {
      padding-top: 8px;
    }

    .section--searchform {
      background-color: $brand-bright-gray;
      margin-bottom: 0;
      padding-bottom: 50px;
      padding-top: 48px;

      .form--search {
        .row {
          display: flex;
          margin-left: 0;
          margin-right: 0;
        }

        .form-element {
          &[class*="col-"] {
            padding-left: 0;
            padding-right: 0;
            width: auto;
          }

          &.form-element--pattern {
            flex: 2;
          }

          .input-group-search {
            margin-right: 48px;
          }

          &.form-element--datepicker {
            flex: 1;

            .input-group {
              margin-right: 24px;
            }
          }
        }

        .form-submit {
          background-color: $brand-queen-blue-light;
          border-radius: 0;
          border: none;
          font-family: $font-family-bold;
          font-size: $font-size-base;
          height: 40px;
          margin-top: 0;
          text-transform: none;
        }

        .input-group-addon {
          &:last-child {
            border: none;
          }
        }

        .input-group {
          border: 1px solid $brand-secondary;
          border-radius: 3px;

          .form-control {
            border: none;
            height: 70px;
          }
        }

        input {
          color: $brand-secondary;
          font-family: $font-family-primary;
          font-size: $font-size-base;
          line-height: $font-size-lg;
        }

        .wizicon {
          color: $brand-primary;
          fill: $brand-primary;
          height: 24px;
          width: 24px;
        }
      }

      @media screen and (max-width: $screen-sm) {
        .form--search {
          .form-body {
            .row {
              flex-direction: column;

              .form-element {
                .input-group {
                  margin-right: 0;
                }
              }
            }
          }

          .input-group .form-control {
            height: 48px;
          }

          .section-btn {
            height: 50px;
          }
        }
      }
    }

    .section--any {
      overflow: hidden;
      padding-bottom: 24px;
      padding-top: 48px;
      width: 100%;

      .section-block {
        margin-bottom: 16px;
      }

      .section-header {
        padding-bottom: 48px;

        .section-title {
          margin-bottom: 0;
        }

        .nav-tabs {
          border: none;
        }

        .nav-underline {
          > li {
            &.active {
              > a {
                &::after {
                  background-color: $brand-primary;
                }
              }
            }
          }

          &.nav-underline-alt {
            > li {
              &.active {
                > a {
                  font-family: $font-family-bold;
                }
              }

              > a {
                color: $brand-secondary;
                font-family: $font-family-primary;
                font-size: $font-size-lg;
                font-weight: unset;
                line-height: $font-size-xl;
                opacity: unset;

                &:hover {
                  &::after {
                    background-color: $brand-primary;
                  }
                }

                @media screen and (max-width: $screen-sm) {
                  font-size: $font-size-md;
                  line-height: 20px;
                }
              }
            }
          }
        }

        @media screen and (max-width: $screen-sm) {
          padding-bottom: 30px;

          .nav-tabs {
            > li {
              margin-bottom: 18px;
            }
          }

          .section-title {
            padding-bottom: 20px;
          }
        }
      }

      .section--experts,
      .section--events {
        .section-header {
          padding-bottom: 0;
        }
      }
    }

    .tab-content {
      .tab-pane {
        .section-composite {
          .section-composite {
            padding: 56px 0 16px;

            &::before {
              content: '';
              height: 100%;
              left: -50%;
              position: absolute;
              top: 0;
              width: 200%;
            }

            &:nth-child(odd) {
              &::before {
                background-color: $brand-bright-gray;
              }
            }

            &:nth-child(even) {
              &::before {
                background-color: $brand-white;
              }
            }
          }
        }
      }
    }
  }
}

.section--default.section--webidentities {
  .section-header {
    padding-bottom: 0;
  }

  .section-title {
    border-bottom: 0;
    font-size: $font-size-lg;
    text-align: center;
  }

  .list--webidentities {
    text-align: center;
  }
}

.section--any .section-block {
  margin-bottom: 40px;
}

.section--wiztrust {
  padding: 48px 0 52px;

  .section-block {
    .section-wiz {
      align-items: center;
      display: flex;
      margin: 0 auto;
      max-width: 1000px;
      padding: 0;

      .fingerprint {
        flex-basis: 190px;
        height: 56px;
        margin-right: 40px;

        .wizicon {
          height: 100%;
          width: 100%;
        }
      }

      .section-wiz-text {
        flex: 1;

        .wiztrust-title {
          font-family: $font-trenda-bold;
          font-size: $font-size-xl;
          line-height: 32px;
          padding-bottom: 0;
          color: $brand-primary;
        }

        .content-text {
          color: $text-color;
          font-family: $font-family-regular;
          font-size: $font-size-base;
          line-height: 20px;
          padding-top: 8px;
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    background-color: $brand-bright-gray;
    padding: 32px 0;

    .section-block {
      .section-wiz {
        padding: 0;
        flex-direction: column;

        .fingerprint {
          flex-basis: auto;
          margin-bottom: 24px;
          margin-right: 0;
        }

        .section-wiz-text {
          .wiztrust-title {
            font-size: $font-size-lg;
            line-height: 24px;
          }

          .content-text {
            padding-top: 12px;
          }
        }
      }
    }
  }
}

.section--powered-by {
  padding: 0;

  .section-block {
    padding: 24px 60px;
    width: auto;
  }

  .section-text {
    a {
      p, span {
        display: inline-block;
        vertical-align: bottom;
      }

      p {
        font-size: 12px;
        font-family: $font-family-secondary;
        margin: 0 5px 0 0;
        text-decoration: underline;
      }

      span {
        width: 90px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.section-wrapper .section--features--generic {
  position: absolute;
  right: -80px;
  top: 180px;
  width: 80px;

  .card--feature {
    margin-bottom: 10px;
    text-align: center;

    .card-img {
      float: none;
      margin: auto;
      margin-bottom: $card-spacer-y;
      width: initial;

      img {
        @include size(45px, 45px);
      }
    }

    .card-title {
      color: $brand-secondary;
      font-size: $font-size-xxs;
      font-weight: $font-weight-bold;
      position: static;
      text-transform: uppercase;
      transform: initial;
    }
  }

  @media screen and (max-width: $grid-float-breakpoint) {
    display: none;
  }
}

.layout--press-review-index {
  .section--powered-by {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.layout-content .section--features,
.layout-event .section--features {
  .section-title-icon {
    display: none;
  }

  .card--feature {
    .card-block {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .card-img {
      margin: 0;
      margin-right: $card-spacer-x;
      width: 20%;

      img {
        @include size(45px, 45px);
      }
    }

    .card-title {
      width: 80%;
    }
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    display: none;
  }
}

.layout-tag {
  .layout-hook--main {
    .section {
      &:not(.section--jumbotron-view) {
        &:has(.section-btn) {
          padding-bottom: 56px;

          .section-footer {
            margin-top: 16px
          }
        }

        &.section--even {
          background-color: $brand-white;
        }

        &.section--odd {
          background-color: $brand-bright-gray;
        }
      }
    }
  }

  .layout-hook--main {
    padding-bottom: 80px;
  }

  @media screen and (max-width: $screen-md) {
    .layout-hook--main {
      padding-bottom: 40px;
    }
  }
}

.content-text--blockchain a {
  text-decoration: underline;
}

.section-wrapper {
  .section-header {
    position: relative;

    .section-title {
      border-bottom: 0;
      color: $brand-primary;
      font-family: $font-trenda-black;
      font-size: $font-size-xxxl;
      font-weight: bold;
      line-height: 48px;
      padding-bottom: 56px;
      text-align: center;
      text-transform: uppercase;
    }

    @media screen and (max-width: $screen-md) {
      .section-title {
        font-size: $font-size-xxxl-mb;
        padding-bottom: 24px;
      }
    }
  }
}

.section.section--features {
  padding: 64px 0;

  .section-container {
    max-width: $container-main;
    width: 100%;
  }

  .section-header {
    padding-bottom: 0;

    .section-title {
      padding-bottom: 56px;
      pointer-events: none;

      .section-title-icon {
        display: none;
      }
    }
  }

  .collapse-body {
    width: 100%;
  }

  .section-block {
    margin: 0 -4px;

    .list--list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .list-item {
        flex-basis: 20%;
        min-height: 408px;
        padding: 0 4px;

        &:last-child,
        &:first-child {
          .card--feature {
            .card-title {
              color: $brand-aureolin;
            }

            .wizicon {
              path {
                fill: $brand-aureolin;
              }
            }
          }
        }

        &:nth-child(3) {
          .card--feature {
            .card-title {
              color: $brand-pink;
            }

            .wizicon {
              path {
                fill: $brand-pink;
              }
            }
          }
        }

        &:nth-child(2),
        &:nth-child(4) {
          .card--feature {
            .card-title {
              color: $brand-primary;
            }

            .wizicon {
              path {
                fill: $brand-primary;
              }
            }
          }
        }

        .card--feature {
          .group-btn {
            align-items: center;
            bottom: 40px;
            display: flex;
            left: 24px;
            position: absolute;

            .wizicon {
              height: 24px;
              margin-left: 8px;
              position: relative;
              width: 24px;
              z-index: 2;
            }
          }

          .card-title {
            color: $brand-white;
            font-family: $font-trenda-bold;
            font-size: $font-size-xl;
            line-height: 32px;
            position: relative;
            text-transform: unset;
            z-index: 2;
          }

          .card-img-feature {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;

            img {
              -o-object-fit: cover;
              -webkit-transition: all ease .5s;
              height: 100%;
              left: 0;
              object-fit: cover;
              position: absolute;
              top: 0;
              width: 100%;
            }
          }

          &:hover {
            .card-img-feature {
              img {
                -webkit-transform: scale(1.1);
              }
            }
          }

          .card-block {
            height: 100%;
            padding: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    padding: 56px 0 48px;

    .section-header {
      .section-title {
        padding-bottom: 24px;
      }
    }

    .section-block {
      margin: 0;

      .list--list {
        .list-item {
          flex-basis: 100%;
          min-height: 256px;
          padding: 0;

          & + .list-item {
            margin-top: 16px;
          }
        }
      }
    }
  }
}

.section--footer {
  padding: 40px 0 0;

  .section-container {
    max-width: 100%;
    width: 100%;
  }

  .section-main {
    background: $brand-white;
    margin: 0px;
    padding: 24px 0 35px;

    .section-img {
      height: 56px;
      max-width: 216px;
      width: 100%;

      img {
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  .section--main-header {
    margin: 0 auto;
    max-width: $container-main;
    width: 100%;

    .section--webidentities {
      text-align: center;

      .section-title {
        border-bottom: 0;
        color: $brand-dark-black;
        font-family: $font-family-light;
        font-size: $font-size-base;
        line-height: 20px;
        margin-bottom: 0;
        padding-bottom: 16px;
        text-align: center;
        text-transform: unset;
      }

      .list--vertical {
        display: flex;
        justify-content: center;
        margin: 0 -19px;

        .list-item {
          margin: 0;
          padding: 0 19px;

          .webidentity {
            align-items: center;
            border: none;
            display: flex;
            height: 24px;
            justify-content: center;
            padding: 0;
            width: 24px;
            background-color: transparent;

            .wizicon {
              path {
                fill: $brand-primary;
              }
            }

            &-linkedin {
              border-radius: 5px;
              padding: 2px;
              background-color: $brand-primary;

              .wizicon {
                path {
                  fill: $brand-white;
                }
              }
            }
          }
        }
      }

      .wizicon {
        height: 24px;
        width: 24px;
      }
    }

    .main-footer {
      display: flex;
      justify-content: center;
      list-style: none;
      margin: 0 -32px;
      padding: 35px 0;

      > div {
        padding: 0 32px;
      }

      h4 {
        color: $brand-primary;
        font-family: $font-trenda-bold;
        font-size: $font-size-base;
        font-weight: unset;
        line-height: 20px;
        margin-bottom: 16px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        .nav-item {
          display: flex;

          .nav-link {
            color: $brand-primary;
            font-family: $font-family-light;
            font-size: $font-size-sm;
            line-height: 16px;
          }

          & + .nav-item {
            margin-top: 8px;
          }
        }
      }

      .offie-dropdown {
        min-width: unset;
        opacity: 1;
        position: relative;
        visibility: visible;

        .offie-block {
          background: none;
          border: 0;
          box-shadow: none;

          .nav-link {
            color: $bg-nav-text-color;
            font-family: $font-family-light;
            font-size: $font-size-sm;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;

            &:active,
            &:focus,
            &:hover {
              background: none;
              color: $brand-white;
            }
          }
        }

        &.offie--active {
          opacity: 1;
          position: relative;
          transition-delay: 0s;
          visibility: visible;
        }
      }
    }
  }

  .section--corporate {
    background-color: $brand-primary;

    .footer-block {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      max-width: $container-main;
      padding: 40px 0;
      width: 100%;

      .block-copyright {
        padding-top: 16px;

        p {
          color: $brand-white;
          font-family: $font-trenda-light;
          font-size: $font-size-sm;
          line-height: 16px;
          margin: 0;
        }
      }

      .nav-section-corporate {
        align-items: center;
        display: flex;
        list-style: none;
        margin: 0 -12px;
        padding-left: 0;

        .nav-item {
          padding: 0 12px;

          .nav-link {
            color: $brand-white;
            font-family: $font-trenda-light;
            font-size: $font-size-sm;
            line-height: 16px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen-md) {
    padding-top: 0;

    .section-container {
      padding: 0;
      .section--main-header {
        padding: 0 16px;

        .section-main {
          padding: 32px 0 24px;
        }

        .section--webidentities {
          align-items: center;
          display: flex;
          flex-direction: column;
          padding-bottom: 24px;

          .list--vertical {
            margin: 0  -8px;

            .list-item {
              padding: 0 8px;
            }
          }
        }

        .main-footer {
          align-items: center;
          flex-direction: column;
          margin: 0;
          padding: 24px 0;

          > div {
            padding: 0;

            & + div {
              margin-top: 32px;
            }
          }

          h4 {
            text-align: center;
          }

          ul {
            .nav-item {
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .section--corporate {
        .footer-block {
          flex-direction: column;
          padding: 24px 16px;

          .nav-section-corporate {
            flex-wrap: wrap;
            margin: 0 -8px;

            .nav-item {
              padding: 0 8px;
              margin-bottom: 12px;
            }
          }

          .block-copyright {
            padding-top: 12px;
          }
        }
      }
    }
  }
}

.med-nav-access {
  background-color: $brand-primary;
  border-radius: 4px;
  color: $brand-white;
  cursor: pointer;
  display: none;
  font-family: $font-family-primary;
  font-size: $font-size-base;
  left: 0;
  padding: 12px 24px;
  position: absolute;
  top: 0;
  transition: all .2s cubic-bezier(.2,0,0,1);
  z-index: 2;

  &:hover {
    color: $brand-white;
  }

  &:focus {
    color: $brand-white;
    z-index: 1081;
  }
}
