@mixin make-block-center($width) {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: $width;
}

@mixin make-columns($nbColumns, $gap-size) {
    -moz-columns: $nbColumns;
    -webkit-columns: $nbColumns;
  columns: $nbColumns;

    -moz-column-gap: $gap-size;
    -webkit-column-gap: $gap-size;
  column-gap: $gap-size;
}

@mixin image-view($ratio-w, $ratio-h) {
  height: 0;
  padding-top: calc((100% / #{$ratio-w}) * #{$ratio-h});
  position: relative;
  width: 100%;

  img {
    -o-object-fit: cover;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    left: 0;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@mixin text-line-clamp($rows) {
  display: -webkit-box;
  -webkit-line-clamp: $rows;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin section-title-bottom-border($border-color) {
  &:after {
    background: $border-color;
    content: "";
    display: inline-block;
    height: 4px;
    width: 64px;
  }
}

@mixin background-alternate ($bg-color, $card-bg-color: "transparent") {
  background-color: $bg-color;

  @if ($bg-color == $brand-primary) {
    .section-wrapper {
      .section-header {
        .section-title {
          color: $brand-blue-sky;
        }
      }
    }

    .card {
      .card-block {
        .card-title,
        .card-subtitle,
        .card-btn .card-btn-text,
        .card-btn .card-btn-icon .wizicon path {
          color: $brand-white;
        }
      }

      .dash {
        color: $brand-white;
      }

      .list--tags .label--tags {
        color: $brand-pink;
      }

      .card-btn .card-btn-icon .wizicon path {
        fill: $brand-white;
      }
    }

    @include make-event-card();
  }
  @else {
    @include make-event-card-primary();
  }
}
