.form {
  .form-submit {
    @extend .btn-default;
  }
}

.input-group-btn--wizicon .btn {
  border-width: 0;
  padding: 12.5px 12px;
}

select.form-control {
  min-width: 125px;
}

.form-search {
  input[type="text"] {
    $bg-color: rgba($brand-light, 0.4);

    background-color: $bg-color;
    border-color: transparent;
    color: #fff;
    font-size: $font-size-sm;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;

    @include placeholder(#fff);
  }
}

.input-group .form-control:focus + .input-group-btn .btn-secondary {
  border-color: $brand-secondary-dk;
}

.input-group-btn:last-child >.btn {
  margin-left: 0;
}

.form-search--compact {
  margin: auto;

  .form-title {
    color: #fff;
    font-size: $font-size-lg;
    line-height: $input-height-base;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    width: 75%;

    .form-title {
      float: left;
      margin-bottom: 0;
    }

    .form-body {
      overflow: hidden;
      padding-left: 25px;
    }
  }
}
