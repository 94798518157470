@font-face {
  font-family: "Vinci-Sans-Light";
  font-style: normal;
  font-weight: 300;
  src: url('../../../fonts/Vinci-Sans-Light.woff') format("woff")
}

@font-face {
  font-family: "Vinci-Sans-Regular";
  font-style: normal;
  src: url('../../../fonts/Vinci-Sans-Regular.ttf') format('truetype'), url('../../../fonts/Vinci-Sans-Regular.otf') format("otf");
}

@font-face {
  font-family: "Vinci-Sans-Medium";
  font-style: normal;
  src: url('../../../fonts/Vinci-Sans-Medium.woff') format("woff")
}

@font-face {
  font-family: "Vinci-Sans-Bold";
  font-style: normal;
  src: url('../../../fonts/Vinci-Sans-Bold.woff') format("woff")
}

@font-face {
  font-family: "Trenda-Black";
  font-style: normal;
  src: url('../../../fonts/Trenda-Black.ttf') format('truetype'), url('../../../fonts/Trenda-Black.otf') format("otf");
}

@font-face {
  font-family: "Trenda-Bold";
  font-style: normal;
  src: url('../../../fonts/Trenda-Bold.ttf') format('truetype'), url('../../../fonts/Trenda-Bold.otf') format("otf");
}

@font-face {
  font-family: "Trenda-Light";
  font-style: normal;
  src: url('../../../fonts/Trenda-Light.ttf') format('truetype'), url('../../../fonts/Trenda-Light.otf') format("otf");
}

@font-face {
  font-family: "Trenda-Semibold";
  font-style: normal;
  src: url('../../../fonts/Trenda-Semibold.ttf') format("truetype"), url('../../../fonts/Trenda-Semibold.otf') format("otf");
}

@font-face {
  font-family: "Trenda-Regular";
  font-style: normal;
  src: url('../../../fonts/Trenda-Regular.ttf') format("truetype"), url('../../../fonts/Trenda-Regular.otf') format("otf");
}
