.breadcrumb {
  padding: 0;

  .breadcrumb-item:last-child {
    font-weight: bold;
  }

  .breadcrumb-item,
  .breadcrumb-link {
    font-size: $font-size-sm;
    color: $brand-secondary;
  }

  .breadcrumb-link {
    text-decoration: underline;
  }

  .breadcrumb-item--divider .wizicon {
    fill: $text-color;
  }
}

.breadcrumb--channel {
  padding: 10px 0;
}
