.modal {
  .section:last-child {
    margin-bottom: 0;
  }

  .section-block {
    padding-bottom: 0;
  }

  .section-container {
    width: auto;
  }

  .section-title {
    font-size: $font-size-xl;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .modal-footer {
    border-top: none;
    text-align: center;
  }
}
