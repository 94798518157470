.loader--bubble {
  display: none;

  svg {
    @include size(40px, 40px);
    display: block;
    margin: auto;
    padding: 10px 0;
  }

  .dot {
      -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
      -webkit-animation: bouncedelay 1s infinite ease-in-out both;
    animation: bouncedelay 1s infinite ease-in-out both;
  }

  .dot-1 {
      -webkit-animation-delay: -.54s;
    animation-delay: -.54s
  }

  .dot-2 {
      -webkit-animation-delay: -.48s;
    animation-delay: -.48s
  }

  .dot-3 {
      -webkit-animation-delay: -.32s;
    animation-delay: -.32s
  }

  @-webkit-keyframes bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0.8);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes bouncedelay {
    0%, 80%, 100% {
      transform: scale(0.8);
    }

    40%{
      transform: scale(1);
    }
  }
}

.offie--active {
  .loader--bubble {
    display: block;
  }
}
